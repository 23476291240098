import {PHONE_REGEXP} from './constants/validation'

/**
 * Formats the given phone number to add spaces and symbols
 */
export const formatPhoneNumber = (value: string) => {
  if (!value) return value
  const phoneNumber = value.replace(/[^\d]/g, '')
  return `${phoneNumber.slice(0, 5)} ${phoneNumber.slice(5)}`
}

export const validatePhoneNumber = (value: string) => {
  return {isValid: value.replace(/\s/g, '').match(PHONE_REGEXP)}
}
