import React from 'react'
import {isBefore, isWithinInterval} from 'date-fns'
import type {DefaultContentBody} from 'dc-delivery-sdk-js'
import {useContentStore, useCustomerStore, usePreviewDebugStore} from '../../store/hooks/useStore'
import {OfferCards} from './OfferCards'
import {OfferCard} from './OfferCard'
import {ReadMore} from './ReadMore'
import {CategoryTile, CategoryTiles} from './category-tiles/CategoryTiles'
import {ThinBanner} from './thin-banner'
import {BrandFilter} from './brand-filter/BrandFilter'
import {NavFlyoutGrid} from './NavFlyoutGrid'
import {NavFlyoutBanner} from './NavFlyoutBanners'
import {StickyBanner} from './sticky-banner/StickyBanner'
import {AllContent} from './AllContent'
import {InfoCardList} from './InfoCardList'
import {HeroBanner} from './HeroBanner'
import {ContentText} from './ContentText'
import {CategoryHeadlineBanner} from './CategoryHeadlineBanner'
import {HeroBannerCarousel} from './HeroBannerCarousel'
import {PromoBanner} from './PromoBanner'
import {CountdownTimer} from './CountdownTimer'
import {ButtonGroup} from './ButtonGroup'
import {CalloutMessage} from './CalloutMessage'
import {ContentBox} from './ContentBox'
import {AlternatingTextImage} from './AlternatingTextImage'
import {ContentAccordion} from './ContentAccordion'
import {ProductCarousel} from './ProductCarousel'
import {ContentPage} from './ContentPage'
import {GridCard} from './GridCard'
import {HTMLContentBlock} from './HTMLContentBlock'
import {ContentTabGroup} from './ContentTabGroup'
import {PlaceholderContent} from './PlaceholderContent'
import {Recipe} from './Recipe'
import {ContentVideo} from './ContentVideo'
import {ContentAccordion2ColumnLayout} from './content-accordion-2-column-layout'
import {observer} from 'mobx-react'
import {XRayPreviewWrapper} from '../amplience-preview/x-ray-preview-wrapper'
import {PDPBadgeAssetVizualisationWrapper} from './product-badges'
import {Tab} from '../tabs'
import CategoryPage from '../../pages/category'
import Home from '../../pages/home'
import {DateUtils} from '../../utils/date-utils'
import {ContentSlot} from './ContentSlot'
import {MarketingForm} from './marketing-form'
import {ProductInformationVizualization} from './ProductInformation'
import DesktopNavigation from '../header/desktop-navigation'
import {MegaMenu} from '../header/mega-menu'

/**
 * @todo - wrap CMS components with `loadable`.
 * */

export interface VariationContent {
  contentItems: SlotItem[]
  customerGroups: {
    rank: number
    customerGroups: {id: string; name: string}[]
  }
}

export interface SlotContentItem extends DefaultContentBody {
  default?: SlotItem[]
  variations?: VariationContent[]
}

export interface ReoccurringSlotContentItem extends SlotContentItem {
  daysActive: {
    friday: boolean
    monday: boolean
    saturday: boolean
    sunday: boolean
    thursday: boolean
    tuesday: boolean
    wednesday: boolean
  }
  timeActive: {
    startTime: string
    endTime: string
  }
}

export interface SlotItem extends DefaultContentBody {}

export interface SlotProps {
  deliveryKey?: string
  contentId?: string
  data?: SlotContentItem
}

export const Slot = observer(({deliveryKey, contentId, data}: SlotProps) => {
  const cms = useContentStore()
  const {showSlots} = usePreviewDebugStore()

  const key = deliveryKey && deliveryKey !== 'null' ? deliveryKey : contentId

  const content = data || cms.contentById[key!]

  const components = useContentToDisplay(content)

  return showSlots ? (
    <XRayPreviewWrapper title="SLOT" isComponent={false} content={content}>
      <SlotContent content={components} />
    </XRayPreviewWrapper>
  ) : (
    <SlotContent content={components} />
  )
})

interface SlotContentProps {
  content: SlotItem[] | null
}

const SlotContent = ({content}: SlotContentProps) => {
  const {showContent} = usePreviewDebugStore()

  if (!content) return null

  return (
    <>
      {content
        ?.filter((component): component is SlotItem => !!component)
        ?.map((component, idx) => {
          if (Array.isArray(component) && component.length) {
            return <SlotContent content={component} />
          }

          if (!component?._meta?.schema) {
            return null
          }

          const Component = getComponentForSchema(component._meta.schema)

          if (!Component) return null

          return showContent ? (
            <XRayPreviewWrapper title="CONTENT" isComponent={true} content={component}>
              <Component key={component._meta.schema + idx} content={component} />
            </XRayPreviewWrapper>
          ) : (
            <Component key={component._meta.schema + idx} content={component} />
          )
        })}
    </>
  )
}

export const useContentToDisplay = (slotContent: SlotContentItem) => {
  const {params, selectedPreviewCustomerGroup} = useContentStore()
  const {currentCustomerGroups} = useCustomerStore()

  const isSlotWithoutVariations = (slotContent: SlotContentItem) => {
    return !slotContent.default && !slotContent.variations
  }

  const isSlotWithReoccurringContent = (
    slotContent: SlotContentItem,
  ): slotContent is ReoccurringSlotContentItem => slotContent.timeActive && slotContent.daysActive

  const validateLifecycleExpiryTime = (content: DefaultContentBody) => {
    if (!content?._meta?.lifecycle?.expiryTime) return true

    return isBefore(
      params?.isContentPreview && params?.previewTimestamp
        ? new Date(params.previewTimestamp)
        : new Date(),
      new Date(content._meta.lifecycle.expiryTime),
    )
  }

  const validateSlotActiveDays = (slotContent: ReoccurringSlotContentItem) => {
    if (!slotContent?.daysActive || !Object.keys(slotContent.daysActive).length) return false

    const currentDate =
      params?.isContentPreview && params?.previewTimestamp
        ? new Date(params.previewTimestamp)
        : new Date()

    const currentWeekday = DateUtils.format(currentDate, 'EEEE').toLowerCase()
    return slotContent.daysActive[currentWeekday as keyof ReoccurringSlotContentItem['daysActive']]
  }

  const validateSlotActiveTime = (slotContent: ReoccurringSlotContentItem) => {
    const {startTime, endTime} = slotContent?.timeActive || {}

    if (!startTime || !endTime) return false

    const currentDate =
      params?.isContentPreview && params?.previewTimestamp
        ? new Date(params.previewTimestamp)
        : new Date()

    const currentDateStr = DateUtils.format(currentDate, 'yyyy-MM-dd')
    const startDateWithTimeStr = currentDateStr + 'T' + startTime
    const endDateWithTimeStr = currentDateStr + 'T' + endTime

    return isWithinInterval(currentDate, {
      start: new Date(startDateWithTimeStr),
      end: new Date(endDateWithTimeStr),
    })
  }

  const getComponentsBasedOnVariation = (
    content: SlotContentItem,
  ): SlotItem[] | SlotItem | null => {
    if (!validateLifecycleExpiryTime(content)) {
      return null
    }

    if (isSlotWithoutVariations(content)) {
      if (content?._meta?.schema === 'https://www.iceland.co.uk/page/content-page.json')
        return content

      return (
        content.contentSlot ??
        content.slotContent ??
        content.content ??
        content.contentItems ??
        content
      )
    }

    if (isSlotWithReoccurringContent(content)) {
      const dayActive = validateSlotActiveDays(content)
      if (!dayActive) return null

      const timeActive = validateSlotActiveTime(content)

      if (!timeActive) return null
    }

    const {variations, default: defaultVariation} = content

    if (params?.isContentPreview || params?.isContentVisualization) {
      return handleContentPreviewModeContentToShow(content)
    }

    if (currentCustomerGroups?.length && variations) {
      const variationsToShow = [...variations]
        .sort((prevVariation, nextVariation) => {
          return (
            (prevVariation?.customerGroups?.rank ?? 0) - (nextVariation?.customerGroups?.rank ?? 0)
          )
        })
        .find((variation) => getShowingContentFromVariations(variation, currentCustomerGroups))

      return variationsToShow?.contentItems ?? defaultVariation ?? null
    }

    return defaultVariation ?? null
  }

  const handleContentPreviewModeContentToShow = (content: SlotContentItem) => {
    const defaultContent = Array.isArray(content.default)
      ? content.default
      : content.default
      ? [content.default]
      : null

    if (!selectedPreviewCustomerGroup) {
      return defaultContent
    }

    if (selectedPreviewCustomerGroup && content?.variations) {
      const variation = [...content.variations]
        .sort((prevVariation, nextVariation) => {
          return (
            (prevVariation?.customerGroups?.rank ?? 0) - (nextVariation?.customerGroups?.rank ?? 0)
          )
        })
        .find(({contentItems, customerGroups}) => {
          const groups = customerGroups?.customerGroups?.map((group) => group.id) || []

          return contentItems && groups.includes(selectedPreviewCustomerGroup)
        })

      return variation?.contentItems ?? defaultContent
    }

    return defaultContent
  }

  const getShowingContentFromVariations = (
    variation: VariationContent,
    customerGroups: string[],
  ): SlotItem[] | null => {
    const {customerGroups: cg, contentItems} = variation || {}

    if (!cg.customerGroups?.length || !contentItems) {
      return null
    }

    const groups = cg?.customerGroups?.map((group) => group.id) || []
    const customerGroupMatched = customerGroups.some((group) => groups.includes(group))

    return contentItems && customerGroupMatched ? contentItems : null
  }
  try {
    if (!slotContent) {
      return null
    }

    const contentToDisplay = getComponentsBasedOnVariation(slotContent)

    if (!contentToDisplay) return null

    // Makes sure that default content can be returned as a collection.
    return Array.isArray(contentToDisplay) ? contentToDisplay : [contentToDisplay]

  } catch (error) {
    console.error(`Issue rendering slot content. Error: ${error}`)
  }
}

const schemaComponentMap: Record<string, any> = {
  'https://www.iceland.co.uk/content/nav-menu-node.json': DesktopNavigation,
  'https://www.iceland.co.uk/content/nav-menu-node-single.json': DesktopNavigation,
  'https://www.iceland.co.uk/content/nav-list.json': MegaMenu,
  'https://www.iceland.co.uk/content/offer-card.json': OfferCard,
  'https://www.iceland.co.uk/content/offer-cards.json': OfferCards,
  'https://www.iceland.co.uk/content/category-tile.json': CategoryTile,
  'https://www.iceland.co.uk/content/category-tiles.json': CategoryTiles,
  'https://www.iceland.co.uk/content/seo-read-more-text.json': ReadMore,
  'https://www.iceland.co.uk/content/thin-banner.json': ThinBanner,
  'https://www.iceland.co.uk/content/brand-filter.json': BrandFilter,
  'https://www.iceland.co.uk/content/nav-flyout-grid.json': NavFlyoutGrid,
  'https://www.iceland.co.uk/content/nav-flyout-banner.json': NavFlyoutBanner,
  'https://www.iceland.co.uk/content/sticky-banner.json': StickyBanner,
  'https://www.iceland.co.uk/slot/all-content-slot.json': AllContent,
  'https://www.iceland.co.uk/content/info-cards.json': InfoCardList,
  'https://www.iceland.co.uk/content/hero-banner.json': HeroBanner,
  'https://www.iceland.co.uk/content/content-text.json': ContentText,
  'https://www.iceland.co.uk/content/category-headline-banner.json': CategoryHeadlineBanner,
  'https://www.iceland.co.uk/content/carousel.json': HeroBannerCarousel,
  'https://www.iceland.co.uk/content/promo-banner.json': PromoBanner,
  'https://www.iceland.co.uk/content/countdown-timer.json': CountdownTimer,
  'https://www.iceland.co.uk/content/sticky-countdown-banner.json': StickyBanner,
  'https://www.iceland.co.uk/button-group.json': ButtonGroup,
  'https://www.iceland.co.uk/content/content-callout-message.json': CalloutMessage,
  'https://iceland.co.uk/content/content-box.json': ContentBox,
  'https://www.iceland.co.uk/content/text-image.json': AlternatingTextImage,
  'https://www.iceland.co.uk/content/content-accordion.json': ContentAccordion,
  'https://www.iceland.co.uk/content/product-carousel.json': ProductCarousel,
  'https://www.iceland.co.uk/page/content-page.json': ContentPage,
  'https://www.iceland.co.uk/content/in-grid-card.json': GridCard,
  'https://www.iceland.co.uk/content/content/content-html-block.json': HTMLContentBlock,
  'https://www.iceland.co.uk/content/content-tab-group.json': ContentTabGroup,
  'https://www.iceland.co.uk/content/placeholder-content.json': PlaceholderContent,
  'https://www.iceland.co.uk/content/recipe.json': Recipe,
  'https://www.iceland.co.uk/content/sticky-prizes-won-banner.json': StickyBanner,
  'https://www.iceland.co.uk/content/content-video.json': ContentVideo,
  'https://www.iceland.co.uk/content/content-accordion-2-column-layout.json':
    ContentAccordion2ColumnLayout,
  'https://www.iceland.co.uk/content/image-product-badge.json': PDPBadgeAssetVizualisationWrapper,
  'https://www.iceland.co.uk/content/text-product-badge.json': PDPBadgeAssetVizualisationWrapper,
  'https://www.iceland.co.uk/content/top-right-product-badge.json':
    PDPBadgeAssetVizualisationWrapper,
  'https://www.iceland.co.uk/content/content-tab.json': Tab,
  'https://www.iceland.co.uk/page/category-page.json': CategoryPage,
  'https://www.iceland.co.uk/page/homepage.json': Home,
  'https://www.iceland.co.uk/content/home-hero-slot-variation.json': AllContent,
  'https://www.iceland.co.uk/slot/content-slot.json': ContentSlot,
  'https://www.iceland.co.uk/slot/landing-body.json': ContentSlot,
  'https://www.iceland.co.uk/slot/content-hero-slot.json': AllContent,
  'https://www.iceland.co.uk/content/marketing-form.json': MarketingForm,
  'https://www.iceland.co.uk/content/product-information.json': ProductInformationVizualization,
}

function getComponentForSchema(schema: string) {
  return schemaComponentMap[schema]
}
