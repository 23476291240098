import type {CommerceAPI} from 'commerce-api'
import {makeAutoObservable, reaction, runInAction, toJS} from 'mobx'
import {isServer} from '../utils/utils'
import {BasketStore} from './BasketStore'
import {CategoryStore} from './CategoryStore'
import {ContentStore} from './ContentStore'
import {CustomerStore} from './CustomerStore'
import {GlobalStore} from './GlobalStore'
import {ProductStore} from './ProductStore'
import {StoreDetailsStore} from './StoreDetailsStore'
import {PromotionStore} from './PromotionStore'
import {OrderStore} from './OrderStore'
import {Cookies} from '../utils/storages'
import {StoreModel} from './utils'
import {COOKIE_STORAGE_KEYS} from '../utils/constants'
import {PreviewDebugStore} from './PreviewDebugStore'
import {SKELETON_APP_URLS} from '../constants'
import {injectStores} from '@mobx-devtools/tools'
import makeInspectable from 'mobx-devtools-mst'
import {TProductItem} from '../types/store/basket'
import { CustomerLoginResponse } from 'commerce-api/src/types/auth'

export class RootStore implements StoreModel {
  navigate: any
  api: CommerceAPI
  contentStore: ContentStore
  categoryStore: CategoryStore
  productStore: ProductStore
  basketStore: BasketStore
  customerStore: CustomerStore
  globalStore: GlobalStore
  promotionStore: PromotionStore
  storeDetailsStore: StoreDetailsStore
  orderStore: OrderStore
  previewDebugStore: PreviewDebugStore

  constructor(initialState: any, api: CommerceAPI, isVisualization = false) {
    this.navigate = null
    this.api = api
    this.contentStore = new ContentStore(this, initialState?.contentStore)
    this.categoryStore = new CategoryStore(this, initialState?.categoryStore)
    this.productStore = new ProductStore(this, initialState?.productStore)
    this.basketStore = new BasketStore(this, initialState?.basketStore)
    this.customerStore = new CustomerStore(this, initialState?.customerStore)
    this.promotionStore = new PromotionStore(this, initialState?.promotionStore)
    this.orderStore = new OrderStore(this, initialState?.orderStore)
    this.globalStore = new GlobalStore(this, initialState?.globalStore)
    this.storeDetailsStore = new StoreDetailsStore(this, initialState?.storeDetailsStore) // Needs Global store to be populated first
    this.previewDebugStore = new PreviewDebugStore(this, initialState?.previewDebugStore)  

    // This is to debug the mobx state, you also need the following chrome plugins also :-
    //  - https://chromewebstore.google.com/detail/mobx-developer-tools/pfgnfdagidkfgccljigdamigbcnndkod - this will show the change events
    //  - https://chromewebstore.google.com/detail/mobx-developer-tools-pro/kbkofmkelombofknjlippaelfjhbpena?pli=1 - this will show the state
    // ** You need to have `MOBX_DEBUG=true` in your `.env.local` file **
    if (!isServer && process.env.MOBX_DEBUG === 'true') {
      injectStores({
        customerStore: this.customerStore,
        contentStore: this.contentStore,
        categoryStore: this.categoryStore,
        productStore: this.productStore,
        basketStore: this.basketStore,
        promotionStore: this.promotionStore,
        storeDetailsStore: this.storeDetailsStore,
        orderStore: this.orderStore,
        globalStore: this.globalStore,
        reviewDebugStore: this.previewDebugStore,
      })
      makeInspectable(this.customerStore)
      makeInspectable(this.contentStore)
      makeInspectable(this.categoryStore)
      makeInspectable(this.productStore)
      makeInspectable(this.basketStore)
      makeInspectable(this.promotionStore)
      makeInspectable(this.storeDetailsStore)
      makeInspectable(this.orderStore)
      makeInspectable(this.globalStore)
      makeInspectable(this.previewDebugStore)
    }

    makeAutoObservable(this)

    // We dont want/need to create a guest session during CMS visualization
    if (!isVisualization && !isServer && !SKELETON_APP_URLS.includes(window?.location?.pathname)) {
      this.customerStore.getCustomer()
      this.globalStore.getCustomObjectDateOffsets()

      // Logout customer if expired refresh token or no refresh token when basket, customer
      reaction(
        () => ({
          basket: toJS(this.basketStore)
        }), async () => {
          const refreshTokenExpired = !Cookies.get('refresh-token') && !Cookies.get('refresh-token-guest')
          const accessTokenExpired = this.customerStore.hasAccessTokenExpired()
          if (refreshTokenExpired || accessTokenExpired) {
            await this.customerStore.getCustomer()
          }
      })

      // We want a new session bridge if user logs in/out or get a new basket
      reaction(
        () => this.customerStore.isRegistered,
        async () => {
          if (this.basketStore.basket?.c_deliveryPostalCode && this.customerStore.isRegistered) {
            await this.basketStore.getNextAvailableSlot()
          }
        },
      )

      reaction(
        () => this.basketStore?.basketMergeData,
        async () => {
            if (this.basketStore?.basketMergeData.length > 0 ) {
              let basketsMatch = false
              // If there is items in the basket check they don't match before running merge basket
              if (this.basketStore.basket?.productItems?.length) {
                basketsMatch = this.basketItemMatch(
                  toJS(this.basketStore.basket?.productItems),
                  JSON.parse(this.basketStore?.basketMergeData).productLineItems,
                )
              }

              if (!basketsMatch) {
                this.basketStore.basketMergeType = 'login'
                this.basketStore.initMergingUI = true
              }
            }
        },
      )

      reaction(
        () => this.customerStore?.customerInfo?.authType,
        async (arg, prev) => {
          this.basketStore.basketMergeData = ''
          if (this.customerStore?.customerInfo?.authType === 'registered') {
            this.basketStore.getBeforeYouGoPages()
            this.customerStore.getFrequentlyBought()

            runInAction(() => {
              this.customerStore.sessionBridged = true
            })

            // Close off registration flag now that user is signed in
            if (this.customerStore?.registrationInProgress) {
              this.customerStore.registrationInProgress = false
            }
          } else if (this.customerStore?.isGuest) {
            this.basketStore?.setGuestPostalCode()
          }
          
          const data = this.basketStore.basket?.c_storedBasketJSON
            if (data && data.length) {
              this.basketStore.basketMergeData = data
            }
        },
      )
    }
  }

  basketItemMatch(
    existingBasketItems: TProductItem[],
    basketToMergeItems: TProductItem[],
  ): boolean {
    if (existingBasketItems && basketToMergeItems && existingBasketItems.length !== basketToMergeItems.length) {
      return false
    }

    for (const item1 of existingBasketItems) {
      const match = basketToMergeItems?.find(
        (item2) => item1.productId === item2.productId && item1.quantity === item2.quantity,
      ) || false

      if (!match) {
        return false
      }
    }

    return true
  }

  get asJson() {
    return {
      contentStore: this.contentStore.asJson,
      categoryStore: this.categoryStore.asJson,
      productStore: this.productStore.asJson,
      basketStore: this.basketStore.asJson,
      customerStore: this.customerStore.asJson,
      globalStore: this.globalStore.asJson,
      promotionStore: this.promotionStore.asJson,
      storeDetailsStore: this.storeDetailsStore.asJson,
      orderStore: this.orderStore.asJson,
      previewDebugStore: this.previewDebugStore.asJson,
    }
  }

  getState() {
    return this.asJson
  }
}