import {DEV_URL, LOCAL_URL, PRODUCTION_URL, RELEASE_URL} from './constants'
import {Nullable} from '../../types/utils'

export const isLocal = () => window.location.origin.includes(LOCAL_URL)
export const isDev = () => window.location.origin.includes(DEV_URL)

export const isRelease = () => window.location.origin.includes(RELEASE_URL)

export const isProduction = () => window.location.origin.includes(PRODUCTION_URL)

export const buildPreviewUrl = (
  host: string,
  previewQueryParams?: {vseDomain?: string; timeStamp?: number; customerGroup: Nullable<string>},
) => {
  host = !host.includes('http') ? `http://${host}` : host
  const url = host + '/admin/preview'

  const searchParams = new URLSearchParams(window.location.search)

  const {vseDomain, customerGroup, timeStamp} = previewQueryParams || {}

  if (vseDomain) searchParams.set('vseDomain', vseDomain)
  if (timeStamp) searchParams.set('previewTimestamp', timeStamp.toString())

  if (customerGroup) {
    searchParams.set('previewCustomerGroup', customerGroup)
  } else {
    searchParams.delete('previewCustomerGroup')
  }

  return url + '?' + searchParams.toString()
}

export const getActiveEnvName = () => {
  if (isLocal()) {
    return 'Local'
  }

  if (isDev()) {
    return 'Development'
  }

  if (isRelease()) {
    return 'Release'
  }

  if (isProduction()) {
    return 'Production'
  }
}
