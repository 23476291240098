import React, {useEffect, useState, ReactNode} from 'react'
import {DialogContent, DialogOverlay} from '@reach/dialog'
import {Box, Button, useDisclosure, Stack} from '../../../vanilla'
import {useOverlayTransition} from '../../../vanilla/hooks/useOverlayTransition'
import {CloseIcon, DownloadAppIcon} from '../../icons'
import {useCustomerStore, useGlobalStore, useOrderStore} from '../../../store/hooks/useStore'
import {observer} from 'mobx-react-lite'
import {MobileNavigationList} from './mobile-navigation-list'
import {vars} from '../../../vanilla/vars.css'
import {LoginButtons} from './login-buttons'
import {MobileNavigationLoggedLinks} from './mobile-navigation-logged-links'
import {MobileNavigationLinks} from './mobile-navigation-links'
import {DeliverySlot} from './delivery-slot'
import {getMobileDeviceOS} from '../../../utils/utils'
import {useLocation} from 'react-router-dom'
import useNavigation from '../../../hooks/use-navigation'
import {ExitOrderEditModeSignOutModal} from '../../edit-order-exit-mode-modal'

interface MobileNavigationDrawerProps extends ReturnType<typeof useDisclosure> {
  positionRef: React.RefObject<Element>
}
interface MobileStaticNavigationListProps {
  mobileNavLinks: Array<{link: string; name: string; icon?: ReactNode, key:number}>
  onClose: () => void
}

export const DRAWER_WIDTH = 260
export const TRANSITION_DURATION = 200

const MobileNavigationDrawer = observer(
  ({isOpen, onClose, positionRef}: MobileNavigationDrawerProps) => {
    const {bypassFocusLock, isVisible, isReady} = useOverlayTransition({
      isOpen,
      duration: TRANSITION_DURATION,
    })
    const [positionTop, setPositionTop] = useState(0)

    const {storeAndDaySpecificNavigation, getCustomSitePreferenceById, isCustomSitePreferenceSet, customSitePreferences} = useGlobalStore()
    const {isRegistered, logout} = useCustomerStore()
    const [appCustomPreference, setAppCustomPreference] = useState<string>('none')
    const device = getMobileDeviceOS()
    const location = useLocation()
    const navigate = useNavigation()
    const signOutExitOrderEditMode = useDisclosure(false)
    const {editMode} = useOrderStore()
    
    // Get the right custom preference depending on the device
    useEffect(() => {
      if (device === 'Android') {
        (async function () {
          if (!isCustomSitePreferenceSet('navAppLinkAndroid')) {
            await getCustomSitePreferenceById('navAppLinkAndroid')
          }
          setAppCustomPreference(customSitePreferences['navAppLinkAndroid'] as string)
        }())
      }
      if (device === 'iOS') {
        (async function () {
          if (!isCustomSitePreferenceSet('navAppLinkApple')) {
            await getCustomSitePreferenceById('navAppLinkApple')
          }
          setAppCustomPreference(customSitePreferences['navAppLinkApple'] as string)
        }())
      }
    }, [device])

  const mobileNavLinks: Array<{link: string; name: string; icon?: ReactNode; key: number}> = [
    {
      link: `${appCustomPreference}`,
      name: 'Download the Iceland App',
      icon: <DownloadAppIcon boxSize="16px" />,
    },
    {link: '/customer-support/contact-us', name: 'Contact Us / FAQs'},
    {link: '/free-delivery.html', name: 'Free Delivery'},
    {link: 'https://about.iceland.co.uk/', name: 'About Iceland'},
    {link: '/store-finder', name: 'Store Finder '},
  ].map((item, index) => ({ ...item, key: index }));


    // When opening, find the bottom location of the positionRef to use
    // as our `top` position.
    useEffect(() => {
      if (isReady && !isVisible) {
        const rect = positionRef?.current?.getBoundingClientRect()
        if (rect) {
          setPositionTop(rect.top)
        }
      }
    }, [isReady])

    // Do not render anything unless the component is being used. This tracks
    // the `isOpen` prop and delays it to allow for enter/exit transitions.
    // See the `useOverlayTransition` for more detail.
    if (!isReady) {
      return null
    }

    if (!storeAndDaySpecificNavigation) {
      return null
    }

    const logoutHandler = () => {  
      if (editMode) {
        signOutExitOrderEditMode.onOpen()
        return
      }

      logout()
      // Redirect to home if the user is on the account page
      if (location.pathname.includes('account')) {
        navigate('/')
      }
      onClose()
    }

    return (
      <>
      <ExitOrderEditModeSignOutModal {...signOutExitOrderEditMode} navMenuOnClose={onClose} />
        <DialogOverlay
          dangerouslyBypassFocusLock={bypassFocusLock}
          isOpen={isReady}
          onDismiss={onClose}
          style={{
            height: `calc(100vh - ${positionTop})`,
            top: positionTop,
            left: 0,
            transition: `background-color ${TRANSITION_DURATION}ms ease-out`,
            backgroundColor: isVisible ? 'rgba(0,0,0,0.4)' : 'rgba(0,0,0,0)',
            zIndex: vars.zIndex.overlay,
          }}
        >
          <Box
            as={DialogContent}
            aria-label="Mobile navigation"
            position="absolute"
            top="0px"
            left="0px"
            data-test-selector="mobile-nav"
            bottom="0px"
            zIndex="docked"
            style={{
              width: DRAWER_WIDTH,
              transition: `transform ${TRANSITION_DURATION}ms ease-in`,
              transform: `translateX(${isVisible ? 0 : -DRAWER_WIDTH}px)`,
              backgroundColor: '#f3f3f3',
              boxShadow: '2px 0 10px 0 rgb(0 0 0 / 20%)',
              padding: 0,
              margin: 0,
            }}
          >
            <Stack position="relative" gap="0px" height="full">
              <Button
                variant="unstyled"
                color="inherit"
                position="absolute"
                height="auto"
                paddingX="0px"
                style={{right: -30, top: 4}}
                onClick={onClose}
                iconLeft={<CloseIcon color="white" boxSize="28px" />}
              />
              <Box width="full" height="full" overflowY="auto" overflowX="hidden">
                <Stack position="relative" gap="8px">
                  {!isRegistered && <LoginButtons />}
                      <DeliverySlot />
                      <MobileNavigationLoggedLinks onClose={onClose}/>
                  <MobileNavigationList nav={storeAndDaySpecificNavigation} onClose={onClose} />
                  <MobileStaticLinksMenu mobileNavLinks={mobileNavLinks} onClose={onClose}/>
                  {isRegistered && (
                    <Box paddingX="16px" paddingBottom="20px">
                      <Button
                        variant="outlinePrimary"
                        onClick={logoutHandler}
                        width="full"
                        size="sm"
                        marginTop="8px"
                      >
                        Sign Out
                      </Button>
                    </Box>
                  )}
                </Stack>
              </Box>
            </Stack>
          </Box>
        </DialogOverlay>
      </>
    )
  },
)


const MobileStaticLinksMenu = ({ mobileNavLinks, onClose }: MobileStaticNavigationListProps) => {
  return <MobileNavigationLinks mobileNavLinks={mobileNavLinks} onClose={onClose}/>
}

export default MobileNavigationDrawer
