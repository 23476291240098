import React, {forwardRef} from 'react'
import {Stack, Text, Box, Flex} from '../../vanilla'
import {MastercardAltIcon, VisaAltIcon} from '../icons'
import {
  braintreeInputStyle,
  braintreeInputStyleValid,
  braintreeInputStyleInvalid,
  braintreeInputStylePotentiallyValid,
} from './styles.css'

interface BraintreeLabelProps {
  labelText: string
  icons?: boolean
}

export const BraintreeLabel = ({labelText, icons = false}: BraintreeLabelProps) => {
  return (
    <Flex>
      <Text
        variant="unstyled"
        fontSize="sm"
        lineHeight="none"
        style={{
          color: 'red',
          width: 'fit-content',
          position: 'relative',
          top: '15px',
          left: '5px',
          background: 'linear-gradient(#fff,#fff 25%,#fafafa)',
          paddingLeft: '4px',
          paddingRight: '8px',
        }}
      >
        {' '}
        •{' '}
      </Text>
      <Text
        variant="unstyled"
        fontSize="sm"
        lineHeight="none"
        style={{
          width: 'fit-content',
          position: 'relative',
          top: '15px',
          background: 'linear-gradient(#fff,#fff 25%,#fafafa)',
          paddingRight: '10px',
        }}
      >
        {labelText}
      </Text>
      {icons && (
        <Stack
          direction="row"
          style={{
            width: 'fit-content',
            position: 'relative',
            top: '15px',
            background: 'linear-gradient(#fff,#fff 25%,#fafafa)',
            paddingRight: '10px',
          }}
        >
          <VisaAltIcon width="20px" height="auto" />
          <MastercardAltIcon width="20px" height="auto" />
        </Stack>
      )}
    </Flex>
  )
}

interface BraintreeHostedFieldsProps {
  usingSavedCard: boolean
  braintreeInputs: BraintreeInputsProps
  validationError: BraintreeHostedFieldValidationError
}

interface BraintreeHostedFieldValidationError {
  number: boolean
  cvv: boolean
  expirationDate: boolean
  cardholderName: boolean
}

export interface BraintreeInputsProps extends Record<string, unknown> {
  number: object
  cvv: object
  expirationDate: object
  cardholderName: object
}

export interface FieldProps {
  isPotentiallyValid: boolean
  isValid: boolean
  isFocused: boolean
  isEmpty: boolean
}

export const getBraintreeInputClassName = ({isPotentiallyValid, isValid, isEmpty}: FieldProps) => {
    
  if (!isValid && !isEmpty) {
    return `${braintreeInputStyle} ${braintreeInputStyleInvalid}`
  }
  
  if (isValid) return `${braintreeInputStyle} ${braintreeInputStyleValid}`
  
  if (!isPotentiallyValid) return `${braintreeInputStyle} ${braintreeInputStylePotentiallyValid}`
  
  return braintreeInputStyle
}

export const BraintreeHostedFields = ({
  usingSavedCard,
  braintreeInputs,
  validationError,
  inputRefs
}: BraintreeHostedFieldsProps & { inputRefs: {[key: string]: HTMLInputElement | null}}) => {
  const {number, cvv, expirationDate, cardholderName} = braintreeInputs

  const validationFieldError =
    validationError.cardholderName ||
    validationError.number ||
    validationError.expirationDate ||
    validationError.cvv
  return (
    <Stack bg="white">
      {validationFieldError && (
        <Box paddingY="16px" paddingX={['20px', '28px']} style={{backgroundColor: '#fdf7f8'}}>
          <Text variant="text3" color="error">
            {validationError.number
              ? 'Card number not accepted. Please try again using a Visa or Mastercard.'
              : 'Some payment input fields are invalid.'}
          </Text>
        </Box>
      )}
      <Stack direction={['column', 'row']} spacing={['4px', '16px']}>
        <Stack style={{display: usingSavedCard ? 'none' : 'flex'}}>
          <BraintreeLabel labelText="Name on card" />
          <Box
            ref={(el) => (inputRefs.cardName = el)}
            marginRight={['0px', '76px']}
            className={
              cardholderName ? getBraintreeInputClassName(cardholderName as FieldProps) : braintreeInputStyle
            }
            id="card-holder-name"
          />
        </Stack>
        <Stack style={{display: usingSavedCard ? 'none' : 'flex'}}>
          <BraintreeLabel labelText="Card Number" icons={true} />
          <Box
            ref={(el) => (inputRefs.cardNumber = el)}
            className={number ? getBraintreeInputClassName(number as FieldProps) : braintreeInputStyle}
            id="card-number"
          />
          <Text>Example: 4111111111111111</Text>
        </Stack>
      </Stack>

      <Stack direction={['column', 'row']} spacing={['4px', '16px']}>
        <Stack style={{display: usingSavedCard ? 'none' : 'flex'}}>
          <BraintreeLabel labelText="Exp date: MM / YY" />
          <Box
            ref={(el) => (inputRefs.cardExpiry = el)}
            marginRight={['0px', '76px']}
            className={
              expirationDate ? getBraintreeInputClassName(expirationDate as FieldProps) : braintreeInputStyle
            }
            id="expiration-date"
          />
          <Text>Example: MM/YY</Text>
        </Stack>
        <Stack style={{display: usingSavedCard ? 'none' : 'flex'}}>
          <BraintreeLabel labelText="CV number" />
          <Box
            ref={(el) => (inputRefs.cardCvv = el)}
            className={cvv ? getBraintreeInputClassName(cvv as FieldProps) : braintreeInputStyle}
            id="cvv"
            width="half"
          />
          <Text>Last 3 digits</Text>
        </Stack>
      </Stack>
    </Stack>
  )
}