import React from 'react'
import {Box, Container, Flex, Text, BaseButton} from '../../vanilla'
import {ChevronLeftIcon, BrandLogo, LockIcon, ArrowLeftIcon} from '../icons'
import {Link} from '../link'
import {useHistory, useRouteMatch} from 'react-router-dom'
import {CHECKOUT_SUMMARY_PATH} from '../../constants'
import {getMobileAppOS} from '../../utils/utils'

interface SimpleHeaderProps {
  customerIsRegistered?: boolean
}

const isBackButtonAvailable = () => {
  const appPagesToExcludeBackBtn: string[] = ['/login']
  const {isExact: hideBackButtonForPage} = useRouteMatch(appPagesToExcludeBackBtn) || {
    isExact: false,
  }
  const appType = getMobileAppOS()
  // If in mobile app and there is no history to go back, or we are on app-specific exclusion page, button is unavailable
  if (
    typeof window === 'undefined' ||
    (typeof appType === 'string' && hideBackButtonForPage) ||
    (typeof appType === 'string' && window.history.length <= 1)
  ) {
    return false
  }
  return true
}

export const SimpleHeader = (props: SimpleHeaderProps) => {
  const history = useHistory()

  const {isExact: useSecureIcon} = useRouteMatch([
    CHECKOUT_SUMMARY_PATH,
    '/account/bonus-card/topup',
  ]) || {
    isExact: false,
  }

  const {isExact: isRegisterPage} = useRouteMatch('/account/register') || {
    isExact: false,
  }

  const goBack = () => {
    // If the user is logged in then send them back using the browser history, otherwise send them to the home page.
    if (props?.customerIsRegistered) {
      history.goBack()
      // If the user is on the register page, redirect them to the login page
    } else if (isRegisterPage) {
      history.push('/login')
    } else {
      history.push('/')
    }
  }

  return (
    <>
      <Box as="header" width="full" bg="white">
        <Container paddingX={['16px', '32px', '0px']}>
          <Flex
            align="center"
            justify="space-between"
            height={{mobile: '44px', desktop: '64px'}}
            marginY={['0px', '8px', '0px']}
          >
            <Flex align="center" width="full" justify={['flex-start', 'space-between']}>
              {isBackButtonAvailable() && (
                <BaseButton
                  variant="unstyled"
                  paddingLeft="0px"
                  onClick={() => {
                    goBack()
                  }}
                >
                  <ArrowLeftIcon display={['block', 'none']} color="gray800" boxSize="20px" />
                  <ChevronLeftIcon display={['none', 'block']} color="gray800" boxSize="20px" />
                  <Text variant="text3" color="gray800" display={['none', 'block']}>
                    Back
                  </Text>
                </BaseButton>
              )}

              <Box
                as={Link}
                display="block"
                height={['16px', '24px']}
                aria-label="Iceland Foods UK"
                justifySelf="center"
                marginX={['0px', 'auto']}
                {...((getMobileAppOS() && location.pathname == '/login') ? {} : { to: "/" })}
              >
                <BrandLogo width="auto" height="full" />
              </Box>

              {useSecureIcon && (
                <LockIcon marginLeft={['auto', '0px']} color="accent3" boxSize="24px" />
              )}
            </Flex>
          </Flex>
        </Container>
      </Box>
    </>
  )
}
