export const sentryConfig = {
    release: process.env.npm_package_version,
    server: {
        traceSampleRate: 0.09,
        replaysSessionSampleRate: 0,
        replaysOnErrorSampleRate: 0.16,
        logLevel: "error",
    },
    client: {
        traceSampleRate: 0.09,
        replaysSessionSampleRate: 0,
        replaysOnErrorSampleRate: 0.16,
        logLevel: "error",
        navigationLogLevel: "error",
        clickLogLevel: "error"
    },
}