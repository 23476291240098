import React from 'react'
import '@reach/dialog/styles.css'
import {enableStaticRendering} from 'mobx-react'
import {CommerceAPI} from 'commerce-api'
import {IAppConfigProps, TAppConfig} from '../../types/commerce'
import {CommerceAPIProvider, StoreProvider} from '../../contexts'
import {RootStore} from '../../store/RootStore'
import {GTMProvider} from '../../analytics/react-gtm-hook'
import {isValidVSEDomain} from '../../utils/utils'
import * as Sentry from '@sentry/react'
import { sentryConfig } from '../../sentry.config'

// https://github.com/mobxjs/mobx-react#server-side-rendering-with-enablestaticrendering
enableStaticRendering(typeof window === 'undefined')

export interface InitialStoreState {
  store: InstanceType<typeof RootStore>['asJson']
}

const AppConfig: TAppConfig<IAppConfigProps, InitialStoreState> = ({children, locals}) => {
  
  const gtmConfig = {id: locals.store.globalStore.gtmId}

  if (!locals.store.globalStore.sentryInitialized) {
    Sentry.init({
      enabled: locals.store.globalStore.sentryEnabled,
      dsn: locals.store.globalStore.sentryDSN,
      environment: `sfcc:${locals.store.globalStore.sentryEnv}`,
      release: sentryConfig.release,
      integrations: [
        Sentry.replayIntegration({
          // Additional SDK configuration goes in here, for example:
          maskAllText: true,
          blockAllMedia: true,
        }),
        Sentry.browserTracingIntegration(),
        Sentry.rewriteFramesIntegration()
      ],
      tracesSampleRate: sentryConfig.client.traceSampleRate,
      replaysSessionSampleRate: sentryConfig.client.replaysSessionSampleRate,
      replaysOnErrorSampleRate: sentryConfig.client.replaysOnErrorSampleRate,
      initialScope: (scope) => {
        scope.setLevel(sentryConfig.client.logLevel as SeverityLevel)
        return scope
      },
    })

    locals.store.globalStore.sentryInitialized = true
  }

  return (
    <CommerceAPIProvider value={locals.api}>
      <StoreProvider value={locals.store}>
        <GTMProvider state={gtmConfig}>{children}</GTMProvider>
      </StoreProvider>
    </CommerceAPIProvider>
  )
}

// Called during server render and on first client render to initialze the
// API and store. On the server `ssrHydratedState` will be empty. On the client,
// ssrHydratedState will be populated with the result of `AppConfig.freeze()`.
AppConfig.restore = (locals, ssrHydratedState) => {
  let globalStore: any = ssrHydratedState?.store?.globalStore
  let contentStore = ssrHydratedState?.store?.contentStore || {}

  // This condition is only true during SSR.
  if ('context' in locals) {
    const {
      siteId,
      siteCurrency,
      envConfig,
      deployTarget,
      customerCacheClearInterval,
      appHostname,
      locale,
      appOrigin,
      path,
      query,
    } = locals.context

    const currency = siteCurrency || 'GBP'

    // Set the initial state for the global store
    globalStore = {
      deployTarget,
      customerCacheClearInterval,
      appHostname,
      appOrigin,
      locale,
      currency,
      siteId,
      algoliaAppId: envConfig.algoliaAppId,
      clientId: 'client-id',
      organizationId: 'organization-id',
      algoliaApiKey: envConfig.algoliaAPIKey,
      algoliaIndexBase: envConfig.algoliaIndexBase,
      algoliaAnalyticsAPIKey: envConfig.algoliaAnalyticsAPIKey,
      einsteinConfiguration: envConfig.einsteinConfiguration,
      loqateAPIKey: envConfig.loqateAPIKey,
      recaptchaSiteKey: envConfig.recaptchaSiteKey,
      googleMapsAPIkey: envConfig.googleMapsAPIkey,
      gtmId: envConfig.gtmId,
      sentryInitialized: false,
      sentryEnabled: envConfig.sentryEnabled,
      sentryEnv: envConfig.sentryEnv,
      sentryDSN: envConfig.sentryDSN,
      paypalClient: envConfig.paypalClient
    }

    contentStore = {
      contentClientConfig: {
        hubName: process.env.AMPLIENCE_HUB,
        locale,
      },
      params: {
        isContentPreview: path?.includes('/admin/preview') && isValidVSEDomain(query?.vseDomain),
        isContentVisualization: path?.includes('/admin/visualization'),
        previewCustomerGroup: query?.previewCustomerGroup,
        vseDomain: query?.vseDomain,
        contentId: query?.contentId,
        deliveryKey: query?.deliveryKey,
        realtime: !!query?.realtime,
        previewTimestamp: parseInt(query?.previewTimestamp),
        snapshotId: query?.snapshotId,
        contentType: query?.contentType,
        navigationContentId: process.env.AMPLIENCE_NAVIGATION_CONTENT_ID,
      },
    }
  }

  const apiConfig = {
    origin: globalStore!.appOrigin,
    siteId : globalStore!.siteId,
    clientId: globalStore!.clientId,
    organizationId: globalStore!.organizationId,
    // Saleforce for Iceland uses default locale - but this is the only place that will be used so the locale for project is set .en-GB
    locale: globalStore!.locale === 'en-GB' ? 'default' : globalStore!.locale,
    currency: globalStore!.currency,
    einsteinConfig: globalStore!.einsteinConfiguration,
  }

  const initialStoreState = ssrHydratedState?.store || {
    globalStore,
    contentStore,
  }

  locals.api = new CommerceAPI(apiConfig)
  locals.store = new RootStore(initialStoreState, locals.api)
}

// Returns data to be hydrated/restored on the client side.
AppConfig.freeze = (locals) => {
  return {store: locals.store.asJson}
}

AppConfig.extraGetPropsArgs = (locals) => {
  return {
    api: locals.api,
    store: locals.store,
  }
}

export default AppConfig
