import React from 'react'
import classNames from 'classnames'
import {DefaultContentBody} from 'dc-delivery-sdk-js'
import {Controller, UseFormReturn} from 'react-hook-form'
import {useIntl} from 'react-intl'
import {observer} from 'mobx-react-lite'

import {amountInput, amountInputContainer, currencyInputSign, topupText} from './styles.css'
import {MAX_BONUS_CARD_TOPUP_AMOUNT} from '../../../../utils/constants'
import {BaseButton, Box, BoxProps, Button, Flex, Input, Text} from '../../../../vanilla'
import {
  quantityInputButton,
  quantityInputRecipeButton,
  quantityInputRecipeInput,
} from '../../../../components/quantity-input/style.css'
import {useBasketStore, useCustomerStore, useGlobalStore} from '../../../../store/hooks/useStore'
import {PlusIcon, MinusIcon} from '../../../../components/icons'
import {ContentText} from '../../../../components/cms/ContentText'
import {TextAlignment} from '../../../../types/cms'

const fastAmountTopUpValues = [5, 10, 15, 20] as const

interface TopUpActionsProps {
  form: UseFormReturn<{amount: string}>
  maxTopUpAmount: number
  promotionMessageContent?: {
    text: string
    alignment: TextAlignment
  } & DefaultContentBody
  isCheckout?: boolean
}

export const TopUpActions = observer(function TopUpActions({
  form,
  promotionMessageContent,
  isCheckout = false,
  maxTopUpAmount = MAX_BONUS_CARD_TOPUP_AMOUNT
}: TopUpActionsProps) {
  const {currency} = useGlobalStore()
  const {bonusCardBalance} = useCustomerStore()
  const {bonusCardMax} = useBasketStore()
  const {formatNumber} = useIntl()
  const maxValue =  isCheckout ? +bonusCardMax : maxTopUpAmount - Number((bonusCardBalance ?? 0))

  const handleAmountChange = (onChange: (amount: string) => void) => (value: string) => {
    const newValue = Number(value) < 0 ? '0.00' : value // Handles minus numbers
    onChange(newValue)
  }
  const formatAmount = (value: number) => {
    form.setValue(
      'amount',
      formatNumber(value, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
        useGrouping: false // This removes the commas breaks input with commas
      }),
    )
  }

  return (
    <Flex
      align="center"
      flexWrap={{desktop: 'nowrap', mobile: 'wrap'}}
      gap={['16px', '16px', '0px']}
      justifyContent={{desktop: 'space-between', mobile: 'center'}}
    >
      <Controller
        name="amount"
        control={form.control}
        render={(renderProps) => {
          const {
            field: {onChange, value},
          } = renderProps
          return (
            <TopUpAmountInput
              size="md"
              variant="primary"
              onChange={handleAmountChange(onChange)}
              marginRight={['16px', '32px']}
              value={value}
              maxValue={maxValue}
              onBlur={(event) => {
                formatAmount(+event.target.value)
              }}
              isCheckout={isCheckout}
            />
          )
        }}
      />

      {!isCheckout && (
        <Flex align="center" gap="8px">
          {fastAmountTopUpValues.map((value) => (
            <BaseButton
              key={`fast-topup-amount-btn-${value}`}
              borderRadius="base"
              variant="unstyled"
              color="accent3"
              style={{background: '#ebf7fb'}}
              width="56px"
              height="36px"
              type="button"
              onClick={() =>
                handleAmountChange((amount) => form.setValue('amount', amount))(
                  formatNumber(value, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                    useGrouping: false // This removes the commas breaks input with commas
                  }),
                )
              }
            >
              {currency && (
                <Text variant="unstyled" fontSize="md" fontWeight="inherit" lineHeight="short">
                  {formatNumber(value, {
                    style: 'currency',
                    currency: currency,
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                    useGrouping: false // This removes the commas breaks input with commas
                  })}
                </Text>
              )}
            </BaseButton>
          ))}
        </Flex>
      )}
      {promotionMessageContent && (
        <ContentText
          content={promotionMessageContent}
          containerProps={{display: 'inline', textAlign: 'center', className: topupText}}
          markdownComponentProps={{
            p: {
              variant: 'unstyled',
              display: 'inline',
              color: 'gray800',
              fontSize: 'inherit',
            },
            h1: {
              variant: 'unstyled',
              display: 'inline',
              color: 'accent0',
              fontSize: 'inherit',
              fontWeight: 'bold',
              textTransform: 'lowercase',
            },
            h2: {
              variant: 'unstyled',
              display: 'inline',
              color: 'accent0',
              fontSize: 'inherit',
              fontWeight: 'bold',
              textTransform: 'lowercase',
            },
            h3: {
              variant: 'unstyled',
              display: 'inline',
              color: 'accent0',
              fontSize: 'inherit',
              fontWeight: 'bold',
              textTransform: 'lowercase',
            },
          }}
        />
      )}
    </Flex>
  )
})

export interface TopUpAmountInputProps extends Omit<BoxProps<'div'>, 'onChange' | 'value'> {
  value: string
  onChange: (value: string) => void
  size?: 'md' | 'sm'
  variant?: 'primary' | 'outlineDark'
  maxValue: number
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void
  isCheckout: boolean
}

export const TopUpAmountInput = observer(function TopUpAmountInput(props: TopUpAmountInputProps) {
  const {
    value,
    onChange,
    size = 'md',
    variant = 'primary',
    maxValue,
    onBlur,
    isCheckout,
    ...rest
  } = props
  const {formatNumber} = useIntl()

  const updateAmount = (isIncrement: boolean) => {
    const newValue = isIncrement ? +value + 1 : +value - 1
    const formattedAmount = formatNumber(newValue, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
      useGrouping: false // This removes the commas breaks input with commas
    })

    onChange(getValidValue(formattedAmount))
  }

  const buttons = quantityInputRecipeButton({size, variant, visible: true})
  const input = quantityInputRecipeInput({size, variant})

  const getValidValue = (value:string) => {
    let newValue = value

    if (maxValue && +value > maxValue) { 
      newValue = formatNumber(maxValue, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
        useGrouping: false // This removes the commas breaks input with commas
      })
    }

    return newValue
  }

  const handleInputValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const {value} = e.target

    onChange(getValidValue(value))
  }

  return (
    <Flex alignItems="center" className={amountInputContainer} {...rest}>
      <Button
        extraClassName={classNames(quantityInputButton, buttons)}
        aria-label="Decrease quantity"
        flexShrink="0"
        variant={variant}
        onClick={() => updateAmount(false)}
        size="sm"
        iconLeft={
          <MinusIcon
            style={{
              height: size === 'sm' ? 10 : 12,
              width: size === 'sm' ? 10 : 12,
              pointerEvents: 'none',
            }}
          />
        }
      />
      <Flex align="center" style={{width: `calc(100% - ${size === 'sm' ? '56px' : '72px'})`}}>
        <Text
          as="span"
          variant="unstyled"
          flexShrink="1"
          className={currencyInputSign}
          style={{width: '40%'}}
        >
          £
        </Text>
        <Box style={{width: '60%'}}>
          <Input
            name="amount"
            width="full"
            className={classNames(input, amountInput)}
            type="number"
            inputMode="numeric"
            value={value}
            onBlur={onBlur}
            validatedSuccessfully={true}
            onChange={handleInputValueChange}
          />
        </Box>
      </Flex>

      <Button
        variant={variant}
        extraClassName={classNames(quantityInputButton, buttons)}
        aria-label="Increase quantity"
        flexShrink="0"
        display={'inline-flex'}
        onClick={() => updateAmount(true)}
        size="sm"
        iconLeft={
          <PlusIcon
            style={{
              height: size === 'sm' ? 10 : 12,
              width: size === 'sm' ? 10 : 12,
              pointerEvents: 'none',
            }}
          />
        }
      />
    </Flex>
  )
})
