import React from 'react'
import {observer} from 'mobx-react'
import classNames from 'classnames'
import {useBasketStore, useCustomerStore, useGlobalStore, useOrderStore} from '../../store/hooks/useStore'
import {BaseButton, Box, Container, Flex, Stack, Text} from '../../vanilla'
import {Spinner} from '../spinner'
import {ChangeOrBookDelivery} from './change-or-book-delivery'
import {CheckPostcode} from './check-postcode'
import {FreeNextDay} from './free-next-day'
import useDeliveryBooking from '../../hooks/use-delivery-booking'
import LoadingOverlay from '../loading-overlay'
import {Link} from '../link'
import {DeliveryDateTime} from './delivery-date-time'
import {ReservationStatus} from './reservation-status'
import {DeliveryAddress} from './delivery-address'
import {bookDeliverySectionContainer, reservationStatusContainer} from './styles.css'
import {Wrapper} from '@googlemaps/react-wrapper'
import {NearestStoreSmall} from '../nearest-store-details/nearest-store-small'
import { DeliveryOrderInfo } from './delivery-order-info'
import { useSlotExpiration } from '../../store/hooks/useSlotExpiration'

export const BookDeliveryContainer = () => {
  const {isRegistered, isGuest} = useCustomerStore()
  const {
    basket,
    currentDeliverySlotId,
    isAppliedPostCodeDeliverable,
    processingAppliedPostCode,
  } = useBasketStore()
  const {isReservationExpired} = useSlotExpiration()
  const {googleMapsAPIkey} = useGlobalStore()
  const {recentOrder} = useOrderStore()

  const {clearPostalCode, reserveSlot, loading} = useDeliveryBooking()
  const showFreeDelivery =
    (isAppliedPostCodeDeliverable === true || isAppliedPostCodeDeliverable == null) &&
    !processingAppliedPostCode

  const failedDeliverableStatus =
    isAppliedPostCodeDeliverable === false && !processingAppliedPostCode

  return (
    <Box backgroundColor="white">
      <Container
        paddingY={!basket && !isGuest ? '0px' : ['16px', '24px']}
        marginX="auto"
        position="relative"
        className={classNames(reservationStatusContainer, bookDeliverySectionContainer)}
      >
        <LoadingOverlay
          containerStyles={{backgroundColor: 'white'}}
          isLoading={loading || (processingAppliedPostCode && !isReservationExpired())}
        />
        {!basket && !isGuest ? (
          <Flex justify="center" align="center" minHeight="96px" width="full">
            <Spinner color="primary" />
          </Flex>
        ) : recentOrder ? 
          <Flex direction={["column", "row"]} gap="16px" alignItems="center">
            <DeliveryOrderInfo order={recentOrder} />
          </Flex>
        : (
          <Stack
            flexDirection={['column', 'row']}
            justify="space-between"
            alignItems={currentDeliverySlotId ? 'center' : 'flex-start'}
            spacing={
              currentDeliverySlotId && !isReservationExpired() ? ['20px', '0px'] : ['12px', '0px']
            }
            paddingBottom={['16px', '0px']}
          >
            {currentDeliverySlotId ? (
              <>
                <Box display={isReservationExpired() ? ['none', 'block'] : 'block'}>
                  <DeliveryDateTime />
                </Box>
                {!isReservationExpired() && <DeliveryAddress />}
                <ReservationStatus rebookSlot={() => reserveSlot(currentDeliverySlotId)} />
              </>
            ) : (
              <>
                <Box style={{minWidth: 300}} maxWidth="full">
                  {showFreeDelivery && <FreeNextDay />}
                  {failedDeliverableStatus && (
                    <Box>
                      <Text
                        textAlign="left"
                        variant="text4"
                        color="accent0"
                        lineHeight="short"
                        data-test-selector="postcode-failure"
                        data-cs-mask=""
                      >
                        Sorry, we&apos;re unable to deliver to {basket?.c_deliveryPostalCode}
                      </Text>{' '}
                      <Text textAlign="left" variant="text4" lineHeight="short">
                        Postcode not right?{' '}
                        <Text
                          as="span"
                          variant="text4"
                          padding="0px"
                          textDecoration="underline"
                          color="accent3"
                          lineHeight="short"
                          cursor="pointer"
                          onClick={clearPostalCode}
                          data-test-selector="change-postcode"
                        >
                          Change it
                        </Text>
                      </Text>
                    </Box>
                  )}
                </Box>

                {basket?.c_deliveryPostalCode ? (
                  <>
                    {isAppliedPostCodeDeliverable ? (
                      <ChangeOrBookDelivery postcode={basket?.c_deliveryPostalCode} />
                    ) : (
                      <Flex align="center" gap={['16px', '24px']} direction={['column', 'row']}>
                        <Wrapper apiKey={googleMapsAPIkey}>
                          <NearestStoreSmall />
                        </Wrapper>
                        {!isRegistered && (
                          <BaseButton
                            as={Link}
                            href="/account/register"
                            variant="primary"
                            style={{minWidth: '138px'}}
                            width={['full', 'auto']}
                            maxHeight="36px"
                          >
                            <Text variant="unstyled" fontSize="inherit" fontWeight="inherit">
                              Register
                            </Text>
                          </BaseButton>
                        )}
                      </Flex>
                    )}
                  </>
                ) : (
                  <CheckPostcode />
                )}
              </>
            )}
          </Stack>
        )}
      </Container>
    </Box>
  )
}

export const BookDelivery = observer(BookDeliveryContainer)
