import React, {useEffect, useState} from 'react'
import {observer} from 'mobx-react'
import {Box, Button, Flex, Stack, Text, useDisclosure} from '../../../vanilla'
import {Modal} from '../../modal'
import {Hit, Promotion} from '../../../store/ProductStore'
import {useContentStore, useGlobalStore, useProductStore} from '../../../store/hooks/useStore'
import {NormalizedPromotion} from '../../../store/PromotionStore'
import {ProductTileCompact, ProductTileCompactSkeleton} from '../../product-tile-compact'
import {Slot} from '../../cms/Slot'
import {PaymentLoaderIcon} from '../../icons'
import {loadingIcon} from '../../payment-processing-overlay/style.css'
import {promotionModalContent} from './styles.css'
import {
  Configure,
  InstantSearch as AlgoliaInstantSearch,
  useInstantSearch,
} from 'react-instantsearch-hooks-web'
import {transformHit} from '../../algolia/util/transformHit'
import {DEFAULT_PRODUCTS_AMOUNT_TO_LOAD} from '../../../utils/constants'
import {MultiBuyPromoOfferMessage} from '.'
import {detectBrowser} from '../../../utils/utils'
import { ModalNavigationProps } from '../../../types/ui'

interface PromotionModalProps extends ReturnType<typeof useDisclosure> {
  promo: NormalizedPromotion
  promotion?: Promotion
  onToggle: () => void
  modalNavigationProps?: ModalNavigationProps
}

interface PromotionalModalProps {
  productIds: string[],
}

export const PromotionModal = observer((props: PromotionModalProps) => {
  const {promo, promotion, onToggle, modalNavigationProps, ...rest} = props
  const {
    algoliaIndexBase,
    selectedAvailabilityDayOffset,
    selectedStoreId,
    searchClient,
    customSitePreferences,
  } = useGlobalStore()

  const hitsPerPage =
    typeof customSitePreferences['promoModalPageSize'] === 'number'
      ? customSitePreferences['promoModalPageSize']
      : DEFAULT_PRODUCTS_AMOUNT_TO_LOAD

  const cms = useContentStore()

  useEffect(() => {
    ;(async function () {
      if (promo.c_contentAsset) {
        await cms.fetchItemByKey(promo.c_contentAsset)
      }
    })()
  }, [promo])

  const indexName = `${algoliaIndexBase}__products__default`

  const [searchConfigPage, setSearchConfigPage] = useState(0)
  const [promotionalModalProps, setPromotionalModalProps] = useState<PromotionalModalProps>()
  const [safariBrowser, setSafariBrowser] = useState(false)
  const availabilityFilter = `matrixAvailability.${selectedAvailabilityDayOffset}:-${selectedStoreId}`
        const inStockFilter = `in_stock:true`

  useEffect(() => {
    const browser = detectBrowser()
    if (browser == 'Safari') {
      setSafariBrowser(true)
    }
  }, [])

  return (
    <Modal
      title="Product promotions"
      size="promotions"
      footerCloseBtnText="Continue shopping"
      dialogContentProps={{
        position: 'relative',
      }}
      {...rest}
    >
      <AlgoliaInstantSearch searchClient={searchClient} indexName={indexName}>
        <Configure
          clickAnalytics
          filters={`productPromotions.nearPromos.ID:${promo.id}`}
          hitsPerPage={hitsPerPage}
          page={searchConfigPage}
          facetFilters={[availabilityFilter, inStockFilter]}
        />
        <Stack
          spacing="28px"
          backgroundColor="gray50"
          className={promotionModalContent}
          style={safariBrowser ? {height: '500px'} : {}}
        >
          <Stack spacing="12px" paddingTop="16px" paddingX={['16px', '28px']}>
            {promo.c_contentAsset && cms.contentById[promo.c_contentAsset] && (
              <Slot deliveryKey={promo.c_contentAsset} />
            )}
            <Text variant="text6">{promo.calloutMsg}</Text>
            {promo.details && <Text variant="text3">{promo.details}</Text>}
            {promotionalModalProps && promotion && (
              <MultiBuyPromoOfferMessage
                promotion={promotion}
                productIds={promotionalModalProps?.productIds}
              />
            )}
          </Stack>
          <PromotionProducts
            setNewPage={(newOffsetNb: number) => setSearchConfigPage(newOffsetNb)}
            setPromotionalModalProps={setPromotionalModalProps}
            onToggle={onToggle}
            modalNavigationProps={modalNavigationProps}
          />
        </Stack>
      </AlgoliaInstantSearch>
    </Modal>
  )
})

interface PromotionProductsProps {
  setNewPage: (newOffsetNb: number) => void
  setPromotionalModalProps: (props: PromotionalModalProps) => void
  onToggle: () => void
  modalNavigationProps?: ModalNavigationProps
}

const PromotionProducts = observer(
  ({setNewPage, setPromotionalModalProps, onToggle, modalNavigationProps}: PromotionProductsProps) => {
    const {status, results} = useInstantSearch()
    const {fetchProducts} = useProductStore()
    const [promotionModalHitsIds, setPromotionModalHitsIds] = useState<string[]>([])

    const isLastPage = results?.nbPages ? results?.page === results?.nbPages - 1 : true
    const isLoading = status === 'loading'
    const showInitialLoadSkeletons = results?.hits?.length === 0 && isLoading

    const handleLoadMore = () => {
      const {hitsPerPage, page, nbPages} = results || {}
      const isLastPage = nbPages ? page === nbPages - 1 : true

      if (!isLastPage && page != null && hitsPerPage != null) {
        typeof setNewPage === 'function' && setNewPage(page + 1)
      }
    }

    useEffect(() => {
      if (results?.hits?.length) {
        const transformedHits = results?.hits.map((hit) => transformHit(hit)) as Hit[]
        const hitIds = transformedHits?.map((hit) => hit.id) ?? []
        fetchProducts(hitIds, false)
        setPromotionModalHitsIds((ids) => [...ids, ...hitIds])
        setPromotionalModalProps({productIds: hitIds})
      }}, [results?.queryID])

    return (
      <>
        {isLoading && (
          <Flex
            position="absolute"
            top="0px"
            left="0px"
            width="full"
            height="full"
            align="center"
            justify="center"
            zIndex="overlay"
            style={{backgroundColor: 'rgba(255, 255, 255, 0.6)'}}
          >
            <PaymentLoaderIcon
              style={{opacity: 0.7}}
              width="72px"
              height="72px"
              className={loadingIcon}
            />
          </Flex>
        )}
        <Box
          display="grid"
          gridTemplateColumns={['1x', '2x']}
          paddingBottom="16px"
          paddingX={['0px', '28px']}
          style={{
            gap: '32px 16px',
          }}
        >
          {showInitialLoadSkeletons
            ? Array.from({length: 5}).map((_, index) => (
                <ProductTileCompactSkeleton key={`skeleton-promo-product-tile-${index}`} />
              ))
            : null}
          {promotionModalHitsIds?.map((id) => (
            <ProductTileCompact key={`promo-product-tile-${id}`} productId={id} onToggle={onToggle} modalNavigationProps={modalNavigationProps} />
          ))}
        </Box>
        <Flex justify="center">
          {!isLastPage && (
            <Button
              variant="dark"
              width="full"
              size="sm"
              onClick={handleLoadMore}
              disabled={isLoading}
              style={{maxWidth: 230}}
              marginBottom={['40px', '60px']}
            >
              Load more
            </Button>
          )}
        </Flex>
      </>
    )
  },
)
