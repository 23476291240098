import React, {useEffect, useState} from 'react'
import {SubmitHandler, useForm} from 'react-hook-form'
import {useLocation} from 'react-router-dom'
import {observer} from 'mobx-react-lite'
import {FormattedMessage} from 'react-intl'
import {Box, Button, Container, Flex, Stack, Text} from '../../vanilla'
import useNavigation from '../../hooks/use-navigation'
import {LoginFields} from '../forms/login-fields'
import {useCustomerStore, useGlobalStore} from '../../store/hooks/useStore'
import {
  CredentialModalPlacement,
  CredentialModalPlacementMap,
  CredentialScreens,
} from '../../types/ui'
import {useSoftLogoutData} from '../../hooks/use-soft-logout-data'
import {CREDENTIAL_SCREENS} from '../../utils/constants'
import {ResetPassword} from '../reset-password-container'
import { LocationState, LogInFormFields } from '../../types/forms/login'
import { Alert } from '../alert'

interface LoginContainerProps {
  inModal?: boolean
  onCreateAccountClickHandler?: () => void
  onForgotPasswordClickHandler?: () => void
  onNavigationHandler?: () => void
  afterLoginHandler?: () => void
  maintainLocation?: boolean
  placement?: CredentialModalPlacement
  searchParams?: string
  redirectTo?: string
  showTwoFactorScreenHandler?: () => void
  setSuccessfulCredentials?: (formData: LogInFormFields) => void
}

// @todo once issue with referring react-router-dom to v5 is resolved remove ts-ignore in this component
export const LoginContainer = observer(
  ({
    inModal,
    onCreateAccountClickHandler,
    onForgotPasswordClickHandler,
    onNavigationHandler,
    maintainLocation,
    afterLoginHandler,
    placement,
    searchParams,
    redirectTo,
    showTwoFactorScreenHandler,
    setSuccessfulCredentials
  }: LoginContainerProps) => {
    const [currentCredScreen, setCurrentCredScreen] = useState<CredentialScreens>(
      CREDENTIAL_SCREENS.LOGIN,
    )
    const navigate = useNavigation()
    const customerStore = useCustomerStore()
    const {
      getCustomSitePreferenceById, isCustomSitePreferenceSet, customSitePreferences
    } = useGlobalStore()
    const {loginCustomer, customerInfo, isRegistered, sessionBridged} = customerStore
    const {softLogout, email: softLogoutEmail, firstName, signOutEntirely} = useSoftLogoutData()
    const [isLoading, setIsLoading] = useState(false);
    const [isTwoFactorEnabled, setTwoFactorEnabled] = useState(false);
    const [alertMessage, setAlertMessage] = useState<string | null>(null);

    const form = useForm<LogInFormFields>({
      defaultValues: {
        email: softLogoutEmail || '',
        password: '',
      },
      mode: 'onSubmit',
    })

    useEffect(() => {
      (async function () {
        if (!isCustomSitePreferenceSet('enableTwoFactorAuth')) {
          await getCustomSitePreferenceById('enableTwoFactorAuth')
        }
        setTwoFactorEnabled(customSitePreferences['enableTwoFactorAuth'] as boolean)
      })()
    });

    const location = useLocation<LocationState>()

    const submitForm: SubmitHandler<LogInFormFields> = async (formData) => {
      setIsLoading(true);
      try {
        await loginCustomer(formData)

        if (customerStore.verificationData?.verificationToken) {
          navigate('/account/verification', 'push', {
            directedFrom: location?.state?.directedFrom || null,
            login: formData.email,
          })
          customerStore.setLastActiveToNow();
          // If called from modal, close the modal.
          return afterLoginHandler?.();
        }
        if (customerStore.twoFactorAuthData?.twoFactorRequired) {
          if(showTwoFactorScreenHandler && setSuccessfulCredentials && isTwoFactorEnabled){
            setSuccessfulCredentials(formData)
            showTwoFactorScreenHandler();
          }
          customerStore.setLastActiveToNow()
          return;
        }
        // (Assumed) Successful Login From Here
        const urlObject = new URL(window.location.href)
        // Checks for token search param and routes to homepage on sign in
        if (urlObject.searchParams.has('Token')) {
          navigate('/')
        }
      } catch (error) {
        const message = "Something's not right, please check and try again."
        form.setError('global' as keyof LogInFormFields, {type: 'manual', message})
      } finally {
        setIsLoading(false);
      }
    }

    // If customer is registered push to account page
    useEffect(() => {
      if (customerInfo?.authType != null && isRegistered) {
        onNavigationHandler?.()

        if (afterLoginHandler && sessionBridged) {
          afterLoginHandler()
        }
        if (location?.state?.directedFrom) {
          navigate(location.state.directedFrom)
        }
        if (searchParams?.includes('EmailToken')) {
          navigate(`/account/edit-profile${searchParams}`)
        } else if (!maintainLocation) {
          navigate(redirectTo || '/')
        }
      }
      if (searchParams){
        const params = new URLSearchParams(searchParams);
        if (params.has('reason') && params.get("reason") === "BadLoginAfterRegister"){
          // This is to catch a scenario where a 2FA challenge has come back from the post register login.
          // And has been redirected here to deal with it.
          setAlertMessage('Your account has been successfully created. Please login!');
        }
      }
    }, [customerInfo, sessionBridged])

    const isInBottomPlacementModal = inModal && placement === CredentialModalPlacementMap.BOTTOM

    return currentCredScreen === CREDENTIAL_SCREENS.LOGIN ? (
      <Container
        data-test-selector="login-container"
        width="full"
        justifyContent="center"
        paddingTop={isInBottomPlacementModal ? '16px' : '32px'}
        size="xs"
        bg="white"
        borderRadius="base"
        paddingX={!inModal ? '16px' : undefined}
        {...(!inModal ? {style: {maxWidth: '540px'}} : {})}
      >
        <Stack
          justify="center"
          align="center"
          gap={isInBottomPlacementModal ? '4px' : '20px'}
          paddingX={isInBottomPlacementModal ? '16px' : '28px'}
          marginBottom={isInBottomPlacementModal ? '40px' : '20px'}
        >
          {softLogout ? (
            <>
              <Text
                as="h2"
                variant="text5"
                lineHeight={isInBottomPlacementModal ? 'short' : 'tall'}
                data-cs-mask=""
              >
                Hi {firstName}
              </Text>
              <Text
                variant={isInBottomPlacementModal ? 'text2' : 'text4'}
                lineHeight="shorter"
                align="center"
              >
                Please re-enter your password to continue
              </Text>{' '}
            </>
          ) : (
            <>
              <Text
                as="h2"
                variant={isInBottomPlacementModal ? 'text4' : 'heading3'}
                lineHeight={isInBottomPlacementModal ? 'short' : 'tall'}
              >
                Sign in
              </Text>
              <Text
                variant={isInBottomPlacementModal ? 'text2' : 'text4'}
                lineHeight="shorter"
                align="center"
              >
                Sign in to see your favourites, complete your order or manage your account.
              </Text>
            </>
          )}
        </Stack>
        <Stack spacing={isInBottomPlacementModal ? '0px' : '32px'}>
          <form onSubmit={form.handleSubmit(submitForm)}>
            <Box paddingX={isInBottomPlacementModal ? '16px' : '28px'}>
              {
                alertMessage ? (
                  <Alert status="info" marginBottom={'16px'}>
                      <Text variant="text3">
                        {alertMessage}
                      </Text>
                  </Alert>
                ) : null
              }
              <LoginFields
                form={form}
                hideForgotPassword={softLogout}
                clickForgotPassword={() => {
                  onForgotPasswordClickHandler?.()
                  setCurrentCredScreen(CREDENTIAL_SCREENS.RESET_PASSWORD)
                }}
                isLoading={isLoading}
              />
            </Box>
          </form>
          {softLogout ? (
            <Flex
              align="center"
              justify="space-between"
              paddingBottom="32px"
              paddingX={isInBottomPlacementModal ? '16px' : '28px'}
            >
              <Text
                variant="text2"
                as="span"
                cursor="pointer"
                onClick={() => {
                  onForgotPasswordClickHandler?.()
                  setCurrentCredScreen(CREDENTIAL_SCREENS.RESET_PASSWORD)
                }}
                textDecoration="underline"
              >
                <FormattedMessage defaultMessage="Forgot password?" />
              </Text>
              <Flex gap="4px">
                <Text variant="text2" as="span">
                  Not you?
                </Text>
                <Text
                  variant="text2"
                  as="span"
                  textDecoration="underline"
                  cursor="pointer"
                  onClick={async () => {
                    await signOutEntirely()
                    onCreateAccountClickHandler?.()
                    navigate('/')
                  }}
                >
                  Sign out
                </Text>
              </Flex>
            </Flex>
          ) : (
            <Stack
              justify="center"
              spacing="16px"
              paddingX={isInBottomPlacementModal ? '16px' : '28px'}
              paddingY="16px"
              borderTop={isInBottomPlacementModal ? '0px' : '1px'}
              borderColor="gray200"
            >
              <Text
                fontSize={isInBottomPlacementModal ? 'md' : 'lg'}
                variant="unstyled"
                align="center"
                weight="bold"
                lineHeight="tall"
              >
                New to Iceland?
              </Text>
              <Button
                variant="secondary"
                onClick={() => {
                  onCreateAccountClickHandler?.()
                  navigate('/account/register')
                }}
                width="full"
                size="sm"
              >
                Create an Account
              </Button>
            </Stack>
          )}
        </Stack>
      </Container>
    ) : (
      <Container
        data-test-selector="reset-password-container"
        width="full"
        justifyContent="center"
        paddingTop="16px"
        size="xs"
        bg="white"
        borderRadius="base"
        paddingX={!inModal ? '16px' : undefined}
        {...(!inModal ? {style: {maxWidth: '540px'}} : {})}
      >
        <Stack
          justify="center"
          align="center"
          gap={isInBottomPlacementModal ? '4px' : '20px'}
          paddingX={isInBottomPlacementModal ? '16px' : '28px'}
          marginBottom={isInBottomPlacementModal ? '40px' : '20px'}
        >
          <ResetPassword
            onBack={() => {
              setCurrentCredScreen(CREDENTIAL_SCREENS.LOGIN)
            }}
          />
        </Stack>
      </Container>
    )
  },
)
