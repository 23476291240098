import {
  VaultManager,
  Client,
  client,
  vaultManager,
  dataCollector,
  threeDSecure,
  hostedFields,
} from 'braintree-web'
import {getAppOrigin} from '@salesforce/pwa-kit-react-sdk/utils/url'
import * as styles from '../components/braintree/styles.css'
import {useCustomerStore} from '../store/hooks/useStore'
import {HostedFields as HostedFieldsInstance} from 'braintree-web/modules/hosted-fields'

export function useBraintree() {
  const {customerInfo} = useCustomerStore()

  const createBraintreeClientInstance = async () => {
    // Try for a specific customer vault first and fall back to generic if none
    const braintreeCustomerId = customerInfo?.c_braintreeCustomerId || `icelandfoodsuk_${customerInfo?.customerNo}`
    let token = await fetch(
      `${getAppOrigin()}/braintree-token?customerId=${braintreeCustomerId}`
    )
    let tokenJson = await token.text()
    if (!tokenJson || tokenJson.length < 0) {
      token = await fetch(`${getAppOrigin()}/braintree-token`)
      tokenJson = await token.text()
    }

    const clientInstance: Client = await client.create({
      authorization: tokenJson,
    })

    return {btClientInstance: clientInstance, tokenJson}
  }

  const createBraintreeHostedFieldsNewCard = async () => {
    const {btClientInstance} = await createBraintreeClientInstance()

    const hostedFieldsInstance: HostedFieldsInstance = await hostedFields.create({
      client: btClientInstance,
      styles: {
        input: styles.braintreeInputStyle,
        '.valid:not(.cardholderName)': {
          'color': 'green'
        },
        '.invalid': {
          'color': 'red'
        }
      },
      fields: {
        number: {
          container: '#card-number',
          maxCardLength: 16
        },
        cvv: {
          container: '#cvv',
          maxlength: 3,
        },
        expirationDate: {
          container: '#expiration-date',
        },
        cardholderName: {
          container: '#card-holder-name',
          type: 'text'
        },
      },
    })

    return hostedFieldsInstance
  }

  const createBraintreeHostedFieldsVaultedCard = async () => {
    const {btClientInstance} = await createBraintreeClientInstance()
    const hostedFieldsInstance: HostedFieldsInstance = await hostedFields.create({
      client: btClientInstance,
      styles: {
        input: styles.braintreeInputStyle,
      },
      fields: {
        cvv: {
          container: '#cvv',
          maxlength: 3,
        },
      },
    })

    return hostedFieldsInstance
  }

  const createBraintreeDataCollector = async () => {
    const {btClientInstance} = await createBraintreeClientInstance()
    const braintreeDataCollector = await dataCollector.create({
      client: btClientInstance,
    })

    return braintreeDataCollector
      .getDeviceData({
        raw: true,
      })
      .then((deviceData) => {
        return deviceData
      })
  }

  const createBraintreeVaultInstance = async () => {
    const {btClientInstance, tokenJson} = await createBraintreeClientInstance()
    const vaultManagerInstance: VaultManager = await vaultManager.create({
      client: btClientInstance,
      authorization: tokenJson,
    })

    return vaultManagerInstance
  }

  const createBraintree3DS = async () => {
    const {tokenJson} = await createBraintreeClientInstance()
    const threeDS = await threeDSecure.create({
      authorization: tokenJson,
      version: 2,
    })

    return threeDS
  }

  const createClientTokenForPaypal = async () => {
    try {
      const {tokenJson} = await createBraintreeClientInstance()

      return {clientToken: tokenJson}
    } catch (error) {
      console.log('Error occurred during token initialization', error)
      return {clientToken: null, error: true}
    }
  }

  return {
    createBraintreeHostedFieldsNewCard,
    createBraintreeHostedFieldsVaultedCard,
    createBraintreeDataCollector,
    createBraintreeVaultInstance,
    createBraintree3DS,
    createClientTokenForPaypal,
  }
}
