import React from 'react'
import ReactMarkdown from 'react-markdown'
import {Box, BoxProps, Text} from '../../vanilla'
import {TextProps} from '../../vanilla/components/Text'
import {Link} from '../link'

export interface MarkdownProps {
  markdown: string
  componentProps?: {
    h1?: TextProps<React.ElementType>
    h2?: TextProps<React.ElementType>
    h3?: TextProps<React.ElementType>
    h4?: TextProps<React.ElementType>
    h5?: TextProps<React.ElementType>
    h6?: TextProps<React.ElementType>
    p?: TextProps<React.ElementType>
    a?: TextProps<React.ElementType>
    ol?: BoxProps<React.ElementType>
    ul?: BoxProps<React.ElementType>
    li?: TextProps<React.ElementType>
  }
}

export const Markdown = ({markdown, componentProps = {}}: MarkdownProps) => {
  return (
    <ReactMarkdown
      components={{
        h1: ({...props}) => (
          <Text as="h1" variant="heading2" {...(componentProps?.h1 ? {...componentProps.h1} : {})}>
            {props?.children}
          </Text>
        ),
        h2: ({...props}) => (
          <Text as="h2" variant="text6" {...(componentProps?.h2 ? {...componentProps.h2} : {})}>
            {props?.children}
          </Text>
        ),
        h3: ({...props}) => (
          <Text as="h3" {...(componentProps?.h3 ? {...componentProps.h3} : {})}>
            {props?.children}
          </Text>
        ),
        h4: ({...props}) => (
          <Text as="h3" {...(componentProps?.h4 ? {...componentProps.h4} : {})}>
            {props?.children}
          </Text>
        ),
        h5: ({...props}) => (
          <Text as="h3" {...(componentProps?.h5 ? {...componentProps.h5} : {})}>
            {props?.children}
          </Text>
        ),
        h6: ({...props}) => (
          <Text as="h3" {...(componentProps?.h6 ? {...componentProps.h6} : {})}>
            {props?.children}
          </Text>
        ),
        ol: ({...props}) => (
          <Box as="ol" marginLeft="16px" {...(componentProps?.ol ? {...componentProps.ol} : {})}>
            {props?.children}
          </Box>
        ),
        ul: ({node, ...props}) => (
          <Box
            as="ul"
            marginLeft="16px"
            style={{listStyleType: 'disc'}}
            {...(componentProps?.ul ? {...componentProps.ul} : {})}
          >
            {props?.children}
          </Box>
        ),
        li: ({node, ...props}) => (
          <Text as="li" {...(componentProps?.li ? {...componentProps.li} : {})}>
            {props?.children}
          </Text>
        ),
        a: ({...props}) => (
          <Text
            as={Link}
            color="accent3"
            href={props?.href}
            {...(props?.title && {'aria-label': props.title})}
            {...(componentProps?.a ? {...componentProps.a} : {})}
          >
            {props?.children}
          </Text>
        ),
        p: ({...props}) => (
          <Text {...(componentProps?.p ? {...componentProps.p} : {})}>{props?.children}</Text>
        ),
      }}
    >
      {markdown || ''}
    </ReactMarkdown>
  )
}
