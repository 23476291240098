import {NavContentItem, NavItem} from '../../store/ContentStore'
import {ImageScreen, MediaLink} from '../../types/cms'

export function getImageUrl(source: MediaLink): string {
  return `https://${source?.defaultHost}/i/${source?.endpoint}/${source?.name}`
}

export function getStaticImageUrl(source: MediaLink): string {
  return `https://${source.endpoint}.a.bigcontent.io/v1/static/${source.name}`
}

export const media: {[key in ImageScreen]: string} = {
  mobile: '(max-width: 768px)',
  tablet: '(min-width: 769px) and (max-width: 1023px)',
  desktop: '(min-width: 1024px)',
}

export function transformNavItem(item: NavContentItem) {
  const {menuDefinition, menuContent, route, flyOutContent} = item
  const result: NavItem = {
    name: menuDefinition?.navDisplayName,
    categoryId: item.route?.page?.categoryId,
    categoryName: item.route?.page?.name,
  }

  const children = menuContent?.slice().sort((curr, next) => {
    const currRanking = curr?.menuDefinition?.ranking ?? Number.MAX_SAFE_INTEGER
    const nextRanking = next?.menuDefinition?.ranking ?? Number.MAX_SAFE_INTEGER

    if (currRanking < nextRanking) {
      return -1
    } else if (currRanking > nextRanking) {
      return 1
    }
    return 0
  })

  // Note that we want to keep the resulting NavItem objects as small as possible.
  // That means that we only add the properties that have a value. This way we don't
  // end up with `null` or `undefined` taking up space in the object.
  // We also can assume that default `color` is black and only keep the color field
  // if It's something different.

  if (route?.path) {
    result.path = route.path
  }

  if (menuContent && menuContent.length > 0) {
    result.children = children?.map(transformNavItem)
  }

  if (menuDefinition.mobileIcon) {
    result.icon = menuDefinition.mobileIcon
  }

  if (menuDefinition.menuTextColor && menuDefinition.menuTextColor.name !== 'Black') {
    result.color = menuDefinition.menuTextColor.color
  }

  if (flyOutContent && flyOutContent.default?.length) {
    result.flyOutContent = flyOutContent
  }

  return result
}
