import React from 'react'
import {observer} from 'mobx-react'
import {Slot, SlotItem} from './Slot'
import {SlotComponent} from '../../types/ui'

export interface AllContentInterface {
  default: SlotItem[]
}

export const AllContent = observer<SlotComponent<AllContentInterface>>(({content}) => {
  return <Slot data={content} />
})
