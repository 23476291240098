import React from 'react'
import {useIntl} from 'react-intl'
import {UseFormReturn} from 'react-hook-form'
import {Stack, Text} from '../../../vanilla'
import {useMarketingFormFields} from '../../../hooks/forms/useMarketingFormFields'
import {MarketingFormFields as IMarketingFormFields} from '../../../types/forms'
import {Field} from '../../field'

interface MarketingFormFieldsProps {
  form: UseFormReturn<IMarketingFormFields>
  name: boolean
  phoneNumber: boolean
  terms: boolean
  checkbox: boolean
  checkboxLabel: string
  checkboxRequired: boolean
}

export const MarketingFormFields = (props: MarketingFormFieldsProps) => {
  const {formatMessage} = useIntl()
  const {name, phoneNumber, checkbox, checkboxRequired = false, checkboxLabel, terms, form} = props

  const fields = useMarketingFormFields({form})

  return (
    <Stack spacing="20px">
      {name ? (
        <>
          <Field {...fields.firstName} inputGroupProps={{style: {maxWidth: 450}}} />
          <Field {...fields.lastName} inputGroupProps={{style: {maxWidth: 450}}} />
        </>
      ) : null}
      <Field {...fields.email} inputGroupProps={{style: {maxWidth: 450}}} />
      {phoneNumber ? <Field {...fields.phone} inputGroupProps={{style: {maxWidth: 450}}} /> : null}
      {checkbox ? (
        <Field
          type="checkbox"
          name="marketingPermission"
          control={form.control}
          defaultValue={false}
          label={checkboxLabel}
          error={form.formState.errors.marketingPermission}
          rules={
            checkboxRequired
              ? {
                  required: formatMessage({defaultMessage: 'This field is required.'}),
                }
              : {}
          }
        />
      ) : null}
      {terms ? (
        <Text variant="text3">
          To view our Privacy & Cookie Policy please{' '}
          <Text
            as="a"
            variant="unstyled"
            color={{default: 'accent3', hover: 'accent3Light'}}
            href="/privacy-policy.html"
            textDecoration="underline"
            lineHeight="none"
          >
            click here
          </Text>
        </Text>
      ) : null}
    </Stack>
  )
}
