import React, {useEffect, useRef, useState} from 'react'
import {flowResult} from 'mobx'
import {observer} from 'mobx-react-lite'
import {useIntl} from 'react-intl'
import {DefaultContentBody} from 'dc-delivery-sdk-js'
import {useForm} from 'react-hook-form'
import {TObservablePage} from '../../types/commerce'
import {useContentStore, useCustomerStore, useGlobalStore} from '../../store/hooks/useStore'
import {DEFAULT_BONUS_CARD_TOPUP_AMOUNT} from '../../utils/constants'
import {BaseButton, Box, Checkbox, Container, Flex, Stack, Text} from '../../vanilla'
import {BonusCardIcon, LockIcon} from '../../components/icons'
import {CalloutMessage} from '../../components/cms/CalloutMessage'
import useNavigation from '../../hooks/use-navigation'
import {TopUpActions} from './partials/top-up-actions/top-up-actions'
import {TopUpSummary} from './partials/top-up-summary'
import {ColorPicker, TextAlignment} from '../../types/cms'
import {balanceBox} from './styles.css'
import {useLocation} from 'react-router-dom'
import {BraintreeHostedFields, BraintreeInputsProps} from '../../components/braintree'
import {useBraintree} from '../../hooks/use-braintree'
import LoadingOverlay from '../../components/loading-overlay'
import {PaymentProcessingOverlay} from '../../components/payment-processing-overlay'
import {ThreeDSecure, ThreeDSecureVerifyPayload} from 'braintree-web/modules/three-d-secure'
import {
  FetchPaymentMethodsPayload,
  HostedFieldsEvent,
  HostedFieldsTokenizePayload,
} from 'braintree-web'
import {PaymentMethod, PaypalVaultedPaymentMethod} from '../checkout/partials/payment-section'
import {
  HostedFields as HostedFieldsInstance,
  HostedFieldsAccountDetails,
} from 'braintree-web/modules/hosted-fields'
import {Link} from '../../components/link'
import {Nullable} from '../../types/utils'
import {usePageDataTracking} from '../../analytics/page-tracking'
import {useBraintreeFieldsValidityWatcher} from '../account/partials/account-payment-cards/use-braintree-fields-validity-watcher'
import {CheckoutButton} from '../checkout/partials/checkout-button'
import {useBasketStore} from '../../store/hooks/useStore'
import {useBonusCard} from '../../store/hooks/useBonusCard'
import {CHECKOUT_ERROR_MESSAGES} from '../../utils/constants'
import { SubmitOrderData } from '../../types/store/basket'
import { AddressFormFields } from '../../types/forms'
import { PayPalAccountDetails } from 'braintree-web/modules/paypal'

const BC_AVAILABILITY_PROMISE_DELIVERY_KEY = 'bonus-card-availability-promise'
const BC_PROMOTION_TOPUP_DELIVERY_KEY = 'bonus-card-top-up-message'
const CREDIT_CARD = 'Credit / Debit Card'
const PAYPAL = 'Paypal'

export const AccountBonusCardTopup: TObservablePage = observer(function AccountBonusCardTopup() {
  const {cardData, cardNumber, maxTopUpAmount} = useBonusCard(true)
  const {pathname} = useLocation()
  const navigate = useNavigation()
  const {formatNumber} = useIntl()
  const {currency, getCustomSitePreferenceById, isCustomSitePreferenceSet, customSitePreferences} =
    useGlobalStore()
  const {isRegistered, loading, topupCustomerBonusCard, customerInfo, addresses} =
    useCustomerStore()
  const {contentById} = useContentStore()
  const {bonusCardStatus} = useBasketStore()
  const [userVaultedCards, setUserVaultedCards] = useState<FetchPaymentMethodsPayload[]>([])
  const [userVaultedCardsLoaded, setUserVaultedCardsLoaded] = useState<boolean>(false)
  const [hostedFieldsLoading, setHostedFieldsLoading] = useState<boolean>(true)
  const [userData, setBraintreeUserData] = useState<string | object | undefined>()
  const [threeDS, setThreeDS] = useState<Nullable<ThreeDSecure>>(null)
  const [hostedFields, setHostedFields] = useState<Nullable<HostedFieldsInstance>>(null)
  const [braintreeFormIsValid, setBraintreeFormIsValid] = useState(false)
  const [braintreeInputs, setBraintreeInputs] = useState<BraintreeInputsProps>(
    {} as BraintreeInputsProps,
  )
  const [saveCreditCard, setSaveCreditCard] = useState<boolean>(false)

  const {
    createBraintreeHostedFieldsNewCard,
    createBraintreeHostedFieldsVaultedCard,
    createBraintreeDataCollector,
    createBraintreeVaultInstance,
    createBraintree3DS,
    createClientTokenForPaypal,
  } = useBraintree()

  const [selectedPayment, setSelectedPayment] = useState<string | null>(null)
  const [savedPayPalPaymentAllowed, setSavedPayPalPaymentAllowed] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const [paypalClientToken, setPayPalClientToken] = useState<string | null>(null)
  const [threeDSError, setThreeDSError] = useState<boolean>(false)
  const [hostedFieldsValdationErrors, setHostedFieldsValidationErrors] = useState({
    cardholderName: false,
    number: false,
    expirationDate: false,
    cvv: false,
  })

  const inputRefs = useRef<{ [key: string]: HTMLInputElement | null }>({
    cardNumber: null,
    cardName: null,
    cardExpiry: null,
    cardCvv: null,
  })

  const hostedFieldsErrors =
    hostedFieldsValdationErrors.cardholderName ||
    hostedFieldsValdationErrors.number ||
    hostedFieldsValdationErrors.expirationDate ||
    hostedFieldsValdationErrors.cvv

  const form = useForm<{amount: string}>({
    defaultValues: {amount: DEFAULT_BONUS_CARD_TOPUP_AMOUNT.toFixed(2)},
    mode: 'onBlur',
  })

  const usingSavedCard = selectedPayment !== PAYPAL && selectedPayment !== CREDIT_CARD
  const lastDigits = cardNumber ? cardNumber.substring(cardNumber.length - 4) : ''
  const topUpValue = form.watch('amount')
  const preferred = addresses.find((addr) => addr.preferred === true)
  const billingAddress = preferred as AddressFormFields
  const [savedCardEnabled, setSavedCardEnabled] = useState<boolean>(false) 
  const [allowSaveCreditCard, setAllowSaveCreditCard] = useState<boolean>(false)
  

  const getClientToken = async () => {
    const {clientToken} = await createClientTokenForPaypal()

    setPayPalClientToken(clientToken || null)
  }

  let threeDSecureEnabled: boolean

  useEffect(() => {
    const setupThreeDSecurePref = async () => {
      if (!isCustomSitePreferenceSet('BRAINTREE_3DSecure_Enabled')) {
        await getCustomSitePreferenceById('BRAINTREE_3DSecure_Enabled')
      }
      threeDSecureEnabled = (customSitePreferences['BRAINTREE_3DSecure_Enabled'] ===
        'enabled') as boolean
    }

    const setupSavedPaypalPref = async () => {
    if (!isCustomSitePreferenceSet('BRAINTREE_savedPaypalTopUpEnabled')) {
      await getCustomSitePreferenceById('BRAINTREE_savedPaypalTopUpEnabled')
    }
    setSavedPayPalPaymentAllowed(customSitePreferences['BRAINTREE_savedPaypalTopUpEnabled'] === true)
  }
  setupSavedPaypalPref()
    setupThreeDSecurePref()
  })

  useEffect(() => {
    if (selectedPayment === 'Paypal') {
      getClientToken()
    }
  }, [selectedPayment])

  const setUpHostedFields = async (newCard = false) => {
    setBraintreeFormIsValid(false)

    if (hostedFields) {
      teardownHostedFields()
    }

    const defaultPaymentMethodDisabled = customSitePreferences['disableDefaultPaymentMethods']
    if (defaultPaymentMethodDisabled) {
      setHostedFieldsLoading(false)
    }

    if (userVaultedCardsLoaded && selectedPayment) {
      if (userVaultedCards.length > 0 && !newCard) {
        setHostedFieldsLoading(true)
        const btHostedFields = await createBraintreeHostedFieldsVaultedCard()
        setHostedFields(btHostedFields)
        setBraintreeFormIsValid(true)
        setHostedFieldsLoading(false)
      } else {
        setHostedFieldsLoading(true)
        const btHostedFields = await createBraintreeHostedFieldsNewCard()
        setHostedFields(btHostedFields)
        setHostedFieldsLoading(false)
      }
    }
  }

  const teardownHostedFields = async () => {
    if (hostedFields) {
      hostedFields?.off('validityChange', () => {})
      await hostedFields.teardown()
      setHostedFields(null)
    }
  }

  const handleEvent = (event: HostedFieldsEvent) => {
    if (selectedPayment === CREDIT_CARD) {
      setBraintreeFormIsValid(
        event.fields['cvv'].isValid &&
          event.fields['cardholderName'].isValid &&
          event.fields['expirationDate'].isValid &&
          event.fields['number'].isValid,
      )

      setBraintreeInputs(event.fields)
    } else {
      setBraintreeFormIsValid(true)
    }
  }

  const setUpBraintreeForms = async () => {
    if (!isCustomSitePreferenceSet('disableDefaultPaymentMethods')) {
      await getCustomSitePreferenceById('disableDefaultPaymentMethods')
    }
    const disabledDefaultPref = customSitePreferences['disableDefaultPaymentMethods']

    // Set up 3DS
    const threeDSInstance = await createBraintree3DS()
    setThreeDS(threeDSInstance)

    // Set Up Vaulted Cards
    const vaultManagerInstance = await createBraintreeVaultInstance()
    vaultManagerInstance.fetchPaymentMethods(function (err, paymentMethods) {
      setUserVaultedCards(paymentMethods ?? [])
      setUserVaultedCardsLoaded(true)

      const defaultCard = paymentMethods?.find((method) => method.default)
      if (!disabledDefaultPref) {
        if (defaultCard) {
          setSelectedPayment(defaultCard.nonce)
        } else if (paymentMethods && paymentMethods?.length > 0) {
          setSelectedPayment(paymentMethods?.[0].nonce)
        } else {
          setSelectedPayment(CREDIT_CARD)
        }
      }
    })

    const btUserData = await createBraintreeDataCollector()
    setBraintreeUserData(btUserData)
  }

  useEffect(() => {
    if (hostedFields) {
      hostedFields.on('validityChange', handleEvent)
      hostedFields.on('blur', handleEvent)
      hostedFields.on('focus', handleEvent)
    }
  }, [hostedFields])

  useEffect(() => {
    if (customerInfo?.customerNo) {
      setUpBraintreeForms()
    }
  }, [customerInfo?.customerNo])

  useEffect(() => {
    if (!hostedFields) {
      setUpHostedFields()
    }
  }, [userVaultedCardsLoaded])

  useEffect(() => {
    if (selectedPayment === CREDIT_CARD) {
      const newCard = true
      if (!hostedFieldsLoading) {
        setUpHostedFields(newCard)
      }
    } else if (selectedPayment !== CREDIT_CARD && selectedPayment !== PAYPAL) {
      const newCard = false
      if (!hostedFieldsLoading) {
        setUpHostedFields(newCard)
      }
    }
  }, [selectedPayment])

  /* Redirect user to login screen if it's not registered customer */
  useEffect(() => {
    if (!isRegistered && !loading) {
      navigate('/login', 'push', {
        directedFrom: pathname,
      })
    }
  }, [isRegistered, loading])

  const handleTopUp = async (topUpData: SubmitOrderData, paymentType: string) => {
    const result = await flowResult(
      topupCustomerBonusCard(topUpData, Number(topUpValue), paymentType),
    )

    if (result.success) {
      navigate('/account/bonus-card/topup/confirmation', 'push', {
        order: result,
      })
    }

    if (result?.reason === 'cvv') {
      setErrorMessage('Credit Card Verification Failed')
    } else {
      setErrorMessage(CHECKOUT_ERROR_MESSAGES.BONUSCARD_ERROR_GENERAL)
    }
    refreshHostedFields()
    setIsLoading(false)
  }

  const topUpBonusCard = async (
    threeDSData: ThreeDSecureVerifyPayload,
    braintreeData?: SubmitOrderData,
  ) => {
    const topUpData: SubmitOrderData = {
      btData: braintreeData ?? null,
      userData: userData as object,
      usingSavedCard: selectedPayment !== PAYPAL && selectedPayment !== CREDIT_CARD,
      threeDSecureData: threeDSData ?? null,
      saveCreditCard: saveCreditCard ?? false,
    }

    handleTopUp(topUpData, 'BT_CREDIT_CARD')
  }

  const handlePayPalOrderSubmission = async (nonce: string, cardType: string) => {
    const topUpData: SubmitOrderData = {
      btData: {
        nonce, 
        details: { cardType }
      },
      billingAddress: billingAddress
    }

    handleTopUp(topUpData, 'PayPal')
  }

  const refreshHostedFields = () => {
    setUserVaultedCardsLoaded(false)
    setHostedFieldsLoading(true)
    setUpBraintreeForms()
    setHostedFieldsLoading(false)
    setIsLoading(false)
  }

  const handleThreeDSCancellation = () => {
    refreshHostedFields()
    setThreeDSError(true)
  }

  const handleThreeDSAuthentication = (savedCard?: Record<string, any>, btData?: HostedFieldsTokenizePayload) => {
    return threeDS?.verifyCard({
      onLookupComplete: (data, next) => {
        next()
      },
      amount: Number(topUpValue),
      nonce: usingSavedCard ? savedCard?.nonce ?? '' : btData?.nonce ?? '',
      bin: usingSavedCard ? savedCard?.details?.bin : btData?.details.bin,
      email: customerInfo?.email,
      billingAddress: {
        givenName: preferred?.firstName,
        surname: preferred?.lastName,
        phoneNumber: preferred?.phone,
        streetAddress: preferred?.address1,
        extendedAddress: preferred?.address2,
        locality: preferred?.city,
        region: preferred?.stateCode,
        postalCode: preferred?.postcode,
        countryCodeAlpha2: preferred?.countryCode,
      },
    }).then(async (payload) => {
      const threeDSAuthenticateFailed =
        payload?.threeDSecureInfo?.status !== 'authenticate_successful'
      const threeDSCancelled =
        payload.rawCardinalSDKVerificationData?.Payment?.ExtendedData?.ChallengeCancel
      if (threeDSCancelled) {
        handleThreeDSCancellation()
      } else if (threeDSAuthenticateFailed) {
        handleThreeDSAuthenticationFailure(payload?.threeDSecureInfo?.status)
      } else {
        topUpBonusCard(payload, btData)
      }
    }).catch((error) => {
      if (error.code == 'THREEDS_CARDINAL_SDK_ERROR') {
        setIsLoading(false)
        setErrorMessage(CHECKOUT_ERROR_MESSAGES.THREEDSECURE_GENERAL)
      }
    })
  }

  const handleThreeDSAuthenticationFailure = (status: string) => {
    setIsLoading(false)
    const verificationStatus = ['lookup_error', 'lookup_failed_acs_error', 'challenge_required']
    const errorMessage = verificationStatus.includes(status)
      ? CHECKOUT_ERROR_MESSAGES.VERIFICATION_FAILED
      : CHECKOUT_ERROR_MESSAGES.THREEDSECURE_GENERAL
    setErrorMessage(errorMessage)
  }

  const tokenizeAndSubmitTopUp = async () => {
    setIsLoading(true)
    setErrorMessage(null)
    setThreeDSError(false)

    const usingSavedCard =
      selectedPayment && selectedPayment !== PAYPAL && selectedPayment !== CREDIT_CARD
    let savedCard: Record<string, any> | undefined

    if (usingSavedCard) {
      savedCard = userVaultedCards.find((card) => card.nonce === selectedPayment)
    }

    let btData: HostedFieldsTokenizePayload | undefined

    try {
      if (usingSavedCard) {
        if(savedCard?.type === 'PayPalAccount') {
          return handlePayPalOrderSubmission(savedCard?.nonce, savedCard?.type)
        } else if (threeDSecureEnabled) {
          return handleThreeDSAuthentication(savedCard)
        }
      }
      await hostedFields?.tokenize(async (err, payload) => {
        btData = payload

        if (err || !btData) {
          setIsLoading(false)
          setErrorMessage(CHECKOUT_ERROR_MESSAGES.BONUSCARD_ERROR_GENERAL)
          console.error(err)
          throw err
        }

        if (threeDSecureEnabled) {
          return handleThreeDSAuthentication(savedCard, btData)
        }
      })
    } catch (err) {
      setIsLoading(false)
      setErrorMessage(CHECKOUT_ERROR_MESSAGES.BONUSCARD_ERROR_GENERAL)
      console.error(`ISSUE WITH BRAINTREE ${err}`)
    }
  }

  const sendPageData = usePageDataTracking()
  const pageType = 'checkout'
  const pageCategory = 'Checkout'
  const paypalTopupEnabled = isCustomSitePreferenceSet('PP_Topup_Enabled')
    ? customSitePreferences['PP_Topup_Enabled'] as boolean
    : true
  const showErrorHeading =
    errorMessage && errorMessage === CHECKOUT_ERROR_MESSAGES.BONUSCARD_ERROR_GENERAL

  useEffect(() => {
    sendPageData(pageType, pageCategory);
  }, [])

  useBraintreeFieldsValidityWatcher(hostedFields, setHostedFieldsValidationErrors)

  // Checks custom pref for allowing saved cards
  useEffect(() => {
    (async function () {
      if (!isCustomSitePreferenceSet('BRAINTREE_savedCardEnabled')) {
        await getCustomSitePreferenceById('BRAINTREE_savedCardEnabled')
      }
      setSavedCardEnabled(customSitePreferences['BRAINTREE_savedCardEnabled'] as boolean)
    }())
  })

   // Checks custom pref for Braintree Vault Mode
   useEffect(() => {
    (async function () {
      if (!isCustomSitePreferenceSet('BRAINTREE_Vault_Mode')) {
        await getCustomSitePreferenceById('BRAINTREE_Vault_Mode')
      }
      setAllowSaveCreditCard(customSitePreferences['BRAINTREE_Vault_Mode'] as boolean)
    }())
  })

  return (
    <>
      {isLoading && <PaymentProcessingOverlay />}
      <Container
        paddingY={['24px', '60px']}
        display="flex"
        flexDirection="column"
        gap={['20px', '36px']}
        paddingX={['0px', '16px']}
      >
        <Box paddingX={['16px', '0px']}>
          <Text variant="heading5" lineHeight="short">
            Top Up My Bonus Card
          </Text>
        </Box>
        <Flex direction={['column', 'row']} gap="16px">
          <Stack
            width={['full', 'two-thirds']}
            spacing="20px"
            style={{maxWidth: 780}}
            flexShrink="0"
          >
            <Box bg="white" width="full">
              <Box
                borderColor="gray200"
                borderBottom="1px"
                paddingY="16px"
                paddingX={['16px', '20px']}
              >
                <Text variant="heading1" lineHeight="shorter">
                  Your Bonus Card:
                </Text>
              </Box>
              <Box paddingY="20px" paddingX="16px">
                <Flex
                  display={['flex', 'inline-flex']}
                  paddingX="12px"
                  paddingY="16px"
                  border="1px"
                  borderColor="gray200"
                  align="center"
                  width={['full', 'auto']}
                  gap="12px"
                >
                  <BonusCardIcon width="40px" height="auto" />
                  <Text variant="text4" lineHeight="shorter" data-cs-mask="">
                    Bonus Card ending {lastDigits}
                  </Text>
                </Flex>
              </Box>
              <Box paddingX="16px" paddingBottom="20px" className={balanceBox}>
                {cardData?.balance !== undefined && (
                  <Text
                    variant="unstyled"
                    fontSize="inherit"
                    lineHeight="shorter"
                    color="inherit"
                    fontWeight="inherit"
                  >
                  Current Balance: {`${formatNumber(cardData.balance, {
                    style: 'currency',
                    currency: currency,
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                  })}`}
                  </Text>
                )}
              </Box>
            </Box>
            <Box bg="white" width="full">
              <Box
                borderColor="gray200"
                borderBottom="1px"
                paddingY="16px"
                paddingX={['16px', '20px']}
              >
                <Text variant="heading1" lineHeight="shorter">
                  Top up amount
                </Text>
              </Box>
              <Stack spacing="12px" paddingX={['16px', '20px']} paddingY="20px">
                <Text variant="text3" lineHeight="shorter">
                  How much would you like to top up?
                </Text>
                <TopUpActions
                  form={form}
                  maxTopUpAmount={maxTopUpAmount}
                  promotionMessageContent={
                    contentById[BC_PROMOTION_TOPUP_DELIVERY_KEY] as {
                      text: string
                      alignment: TextAlignment
                    } & DefaultContentBody
                  }
                />
              </Stack>
            </Box>
            <Box bg="white" width="full">
              {threeDSError && (
                <Box border="2px" borderColor="accent0" padding={['16px', '16px', '16px']}>
                  <Text variant="text3">
                    You'll need to use 3D Secure to pay for your order. If you can't use 3D Secure
                    then please pay with PayPal.
                  </Text>
                </Box>
              )}
              <Flex
                borderColor="gray200"
                borderBottom="1px"
                paddingY="16px"
                paddingX={['16px', '20px']}
                justify="space-between"
                align="center"
              >
                <Flex align="center" gap="8px">
                  <LockIcon width="12px" height="12px" color="textMuted" />
                  <Text variant="heading1" lineHeight="shorter">
                    Top up Method
                  </Text>
                </Flex>
              </Flex>
              <Box position="relative">
                <LoadingOverlay
                  isLoading={!userVaultedCardsLoaded || hostedFieldsLoading}
                  containerStyles={{backgroundColor: 'white'}}
                />
                <Stack>
                  <Flex
                    flexDirection={['column', 'column', 'row']}
                    gap="20px"
                    flexWrap="wrap"
                    paddingX={['16px', '20px']}
                    paddingTop="20px"
                    align="stretch"
                  >
                    {/* Only show saved cards when BRAINTREE_savedCardEnabled is set */}
                    {savedCardEnabled && userVaultedCards?.map((card) => {
                      if (!card?.details || !card.nonce || card?.type === 'PayPalAccount' && !savedPayPalPaymentAllowed)
                        return null

                      if (card?.type === 'PayPalAccount') {
                        return (
                          <PaypalVaultedPaymentMethod
                            checked={selectedPayment === card.nonce}
                            email={(card?.details as PayPalAccountDetails).email ?? ''}
                            onClick={() => {
                              if (errorMessage) setErrorMessage(null)
                              setSelectedPayment(card.nonce)
                            }}
                          />
                        )
                      }

                      const {cardType, lastFour, expirationMonth, expirationYear} = card.details as HostedFieldsAccountDetails

                      return (
                        <PaymentMethod
                          key={card.nonce}
                          method={`${cardType} ending in ${lastFour}`}
                          expirationDate={`Expires on ${expirationMonth}/${expirationYear}`}
                          type={cardType}
                          checked={selectedPayment === card.nonce}
                          onClick={() => setSelectedPayment(card.nonce)}
                        />
                      )
                    })}
                    <PaymentMethod
                      method="Credit / Debit Card"
                      checked={selectedPayment === CREDIT_CARD}
                      type="Generic"
                      onClick={() => setSelectedPayment(CREDIT_CARD)}
                    />
                    {paypalTopupEnabled && (
                      <PaymentMethod
                        method="PayPal"
                        type="PayPal"
                        checked={selectedPayment === PAYPAL}
                        onClick={() => setSelectedPayment(PAYPAL)}
                      />
                    )}
                  </Flex>
                  {errorMessage && (
                    <Stack
                      paddingX={['16px', '20px']}
                      paddingY={['20px', '24px']}
                      style={{background: '#F9E5E9'}}
                    >
                      {showErrorHeading && (
                        <Text variant="heading1" lineHeight="shorter" color="accent0">
                          Unable to top up your Bonus Card
                        </Text>
                      )}
                      <Text variant="text4" lineHeight="shorter" color="accent0">
                        {errorMessage}
                      </Text>
                    </Stack>
                  )}
                  <Box position="relative" paddingX={['16px', '20px']}>
                    {selectedPayment !== null && selectedPayment !== PAYPAL && (
                      <BraintreeHostedFields
                        usingSavedCard={usingSavedCard}
                        braintreeInputs={braintreeInputs}
                        validationError={hostedFieldsValdationErrors}
                        inputRefs={inputRefs.current}
                      />
                    )}
                    {savedCardEnabled && allowSaveCreditCard && selectedPayment === CREDIT_CARD ? (
                      <Box marginTop="8px">
                        <Checkbox
                          id="save-this-credit-payment-method"
                          defaultChecked={false}
                          label="Save this card"
                          hasError={false}
                          onChange={(e) => setSaveCreditCard(e.target.checked)}
                        />
                      </Box>
                    ) : null}
                  </Box>
                </Stack>
              </Box>
              <Box
                borderTop="1px"
                borderColor="border1"
                padding={['16px', '16px', '24px']}
                marginTop="20px"
              >
                <Flex justifyContent="space-between">
                  <BaseButton variant="outlineDark" as={Link} to="/account">
                    <Text variant="unstyled">Back</Text>
                  </BaseButton>
                  <CheckoutButton
                    paypalClientToken={paypalClientToken}
                    selectedPayment={selectedPayment}
                    isEditModeEnabled={false}
                    isTopUp={true}
                    submitOrderProcess={tokenizeAndSubmitTopUp}
                    amountToPay={Number(topUpValue)}
                    billingAddressFormValid={true}
                    billingAddress={billingAddress}
                    setError={setErrorMessage}
                    errorMessage={CHECKOUT_ERROR_MESSAGES.BONUSCARD_ERROR_GENERAL}
                    isPaymentRefunding={false}
                    braintreeFormIsValid={braintreeFormIsValid}
                    bonusCardStatus={bonusCardStatus}
                    hostedFieldsErrors={hostedFieldsErrors}
                  />
                </Flex>
              </Box>
            </Box>
          </Stack>
          <Box bg="white" flexGrow="1" width={['full', 'auto']}>
            <Box borderColor="gray200" borderBottom="1px" padding="16px" width="full">
              <Text variant="heading1" lineHeight="shorter" data-cs-mask="">
                Bonus Card ending in {lastDigits}
              </Text>
            </Box>
            <Stack spacing="0px">
              <TopUpSummary topUpValue={topUpValue} bcBalance={cardData?.balance ?? 0} />
              <Stack
                spacing="16px"
                width="full"
                borderBottom="1px"
                borderColor="gray200"
                padding="16px"
                style={{maxWidth: '380px'}}
              >
                <CheckoutButton
                  paypalClientToken={paypalClientToken}
                  selectedPayment={selectedPayment}
                  isEditModeEnabled={false}
                  isTopUp={true}
                  submitOrderProcess={tokenizeAndSubmitTopUp}
                  amountToPay={Number(topUpValue)}
                  billingAddressFormValid={true}
                  billingAddress={billingAddress}
                  setError={setErrorMessage}
                  errorMessage={CHECKOUT_ERROR_MESSAGES.BONUSCARD_ERROR_GENERAL}
                  isPaymentRefunding={false}
                  braintreeFormIsValid={braintreeFormIsValid}
                  bonusCardStatus={bonusCardStatus}
                  hostedFieldsErrors={hostedFieldsErrors}
                />
              </Stack>
              {contentById[BC_AVAILABILITY_PROMISE_DELIVERY_KEY] && (
                <Box paddingX="16px" paddingY="20px">
                  <Box
                    padding="16px"
                    borderColor="accent3"
                    style={{borderWidth: 4, borderStyle: 'solid'}}
                  >
                    <CalloutMessage
                      content={
                        contentById[BC_AVAILABILITY_PROMISE_DELIVERY_KEY] as {
                          text: {
                            richText: string
                          }
                          spacing: string
                          isTextWhite: boolean
                          heading: string
                          backgroundColor?: ColorPicker
                        } & DefaultContentBody
                      }
                      headingProps={{
                        color: 'accent3',
                        variant: 'unstyled',
                        fontWeight: 'extrabold',
                        style: {fontSize: 27},
                        align: 'center',
                      }}
                      containerProps={{
                        spacing: '12px',
                      }}
                      markdownComponentProps={{
                        p: {
                          color: 'gray800',
                          align: 'center',
                        },
                      }}
                    />
                  </Box>
                </Box>
              )}
            </Stack>
          </Box>
        </Flex>
      </Container>
    </>
  )
})

AccountBonusCardTopup.getProps = async ({store}) => {
  const {contentStore} = store

  if (!contentStore.contentById[BC_AVAILABILITY_PROMISE_DELIVERY_KEY])
    await flowResult(contentStore.fetchItemByKey(BC_AVAILABILITY_PROMISE_DELIVERY_KEY))

  if (!contentStore.contentById[BC_PROMOTION_TOPUP_DELIVERY_KEY])
    await flowResult(contentStore.fetchItemByKey(BC_PROMOTION_TOPUP_DELIVERY_KEY))
}
