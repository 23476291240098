import React from 'react'
import {ColorPicker} from '../../types/cms'
import {SlotComponent} from '../../types/ui'
import {Box, Flex} from '../../vanilla'
import {CTAButton} from '../../vanilla/components/CTAButton'
import * as styles from './styles.css'

interface ButtonGroupButton {
  ctaButtonColor: ColorPicker
  ctaTextColor: ColorPicker
  ctaText: string
  ctaLink: string
}

interface ButtonGroupProps {
  buttonList: Array<ButtonGroupButton>
}

export const ButtonGroup: SlotComponent<ButtonGroupProps> = ({content}) => {
  return (
    <Flex gap={['12px', '20px']} flexWrap="wrap" justify="center">
      {content?.buttonList?.map((cta, idx) => (
        <Box key={`${content?._meta?.deliveryId}-${idx}`} className={styles.buttonGroupButton}>
          <CTAButton
            width="full"
            textAlign="center"
            cta={cta}
            style={{whiteSpace: 'break-spaces'}}
          />
        </Box>
      ))}
    </Flex>
  )
}
