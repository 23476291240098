import React from 'react'
import {LockIcon} from '../../../components/icons'
import {PaypalPaymentButton} from '../../../components/paypal'
import {CheckoutBonusCardStatus} from '../../../types/store/common'
import {BaseButton, Text} from '../../../vanilla'
import {useOrderStore} from '../../../store/hooks/useStore'
import {AddressFormFields} from '../../../types/forms'

interface CheckoutButtonProps {
  submitOrderProcess: () => Promise<void>
  isEditModeEnabled: boolean
  isTopUp: boolean
  selectedPayment: string | null
  amountToPay: number | null | undefined
  billingAddressFormValid: boolean
  setError: React.Dispatch<React.SetStateAction<string | null>>
  errorMessage: string
  isPaymentRefunding: boolean
  braintreeFormIsValid: boolean
  bonusCardStatus: CheckoutBonusCardStatus
  hostedFieldsErrors: boolean
  paypalClientToken: string | null
  billingAddress?: AddressFormFields
}

export const CheckoutButton = (props: CheckoutButtonProps) => {
  const {
    submitOrderProcess,
    isEditModeEnabled,
    isTopUp,
    selectedPayment,
    amountToPay,
    billingAddressFormValid,
    setError,
    errorMessage,
    isPaymentRefunding,
    braintreeFormIsValid,
    bonusCardStatus,
    hostedFieldsErrors,
    paypalClientToken,
    billingAddress
  } = props

  const {isPaymentRequired} = useOrderStore()

  return selectedPayment === 'Paypal' ? (
    <PaypalPaymentButton
      amount={amountToPay ?? 0}
      disabled={!billingAddressFormValid}
      onErrorCallback={() => setError(errorMessage)}
      isTopUp={isTopUp}
      paypalClientToken={paypalClientToken}
      billingAddress={billingAddress}
    />
  ) : (
    <BaseButton
      style={{minWidth: '230px'}}
      variant="primary"
      onClick={() => submitOrderProcess()}
      disabled={
        isPaymentRequired
          ? !billingAddressFormValid ||
            (!isPaymentRefunding
              ? (!braintreeFormIsValid && !bonusCardStatus?.hidePayment) || hostedFieldsErrors
              : false)
          : false
      }
    >
      {isEditModeEnabled ? (
        <Text variant="unstyled">Update Your Order Now</Text>
      ) : (
        <>
          <Text variant="unstyled">{isTopUp ? 'Top up' : 'Pay'} Securely</Text>
          &nbsp;
          <LockIcon />
        </>
      )}
    </BaseButton>
  )
}
