import React, {useEffect, useState} from 'react'
import {observer} from 'mobx-react-lite'
import {SlotComponent} from '../../../types/ui'
import {Box, Button, Flex, Stack, Text} from '../../../vanilla'
import {useLocation, useRouteMatch} from 'react-router-dom'
import {CloseIcon} from '../../icons'
import {ColorPicker, CTA, Image} from '../../../types/cms'
import {
  stickyBannerCloseBtn,
  stickyBannerContainer,
  stickyBannerCtaContainer,
  stickyBannerSlideInNavigationKeyFrame,
  stickyBannerSlideOutNavigationKeyFrame,
  stickyBannerTimerCtaContainer,
} from './styles.css'
import {CmsImage} from '../cms-image'
import {CTAButton} from '../../../vanilla/components/CTAButton'
import {useTimer} from '../../../hooks/use-timer'
import {useSelectPromotionTracking} from '../../../analytics/promotion-tracking'

interface TimerProps {
  countdownBackgroundColor: ColorPicker
  countdownDate: string
  countdownTextColor: ColorPicker
  headline: string
}

interface PrizesProps {
  prizesWonData: Array<{data: string}>
  prizeWonText: {
    backgroundColor: ColorPicker
    textColor: ColorPicker
    prizesWonHeading: string
  }
}
interface StickyBannerCmsContent {
  cta: CTA
  image: {
    imageLink?: string
    image: {
      images: Array<Image>
    }
  }
  excludedUrlPartials: Array<string>
  hideOnMobile: boolean
  closeButton: {
    closeBackgroundColor: ColorPicker
    closeColor: ColorPicker
  }
  timer?: TimerProps
}

interface StickyBannerProps extends StickyBannerCmsContent {
  prizesWonData: PrizesProps['prizesWonData']
  prizeWonText: PrizesProps['prizeWonText']
}

const animationMap = {
  show: stickyBannerSlideInNavigationKeyFrame,
  hide: stickyBannerSlideOutNavigationKeyFrame,
} as const

const PrizesWon = ({prizesWonData, prizeWonText}: PrizesProps) => {
  const {backgroundColor, prizesWonHeading, textColor} = prizeWonText
  const today = new Date()
  const day = today.getDate() - 1
  const hour = today.getHours() - 1
  const prizeDay = prizesWonData[day]
  const prizeArray = prizeDay?.data.split(',')
  const prizeCount = prizeArray[hour]

  return (
    <Box
      style={{
        backgroundColor: backgroundColor?.color || '#fff',
        borderTopLeftRadius: '4px',
        borderTopRightRadius: '4px',
      }}
      width="full"
      padding="4px"
    >
      <Box marginTop="12px">
        <Text
          textAlign="center"
          style={{color: textColor?.color, fontSize: '30px'}}
          weight="extrabold"
        >
          {prizeCount}
        </Text>
      </Box>
      <Text
        variant="text7"
        textAlign="center"
        style={{color: textColor?.color, margin: '8px'}}
        textTransform="uppercase"
      >
        {prizesWonHeading}
      </Text>
    </Box>
  )
}

const Timer = ({timer}: {timer: TimerProps}) => {
  const shouldPrependZeros = true
  const {countdownBackgroundColor, countdownDate, countdownTextColor, headline} = timer
  const {hours, minutes, seconds} = useTimer(countdownDate, shouldPrependZeros)

  const textColor = {color: countdownTextColor?.color || '#000'}

  return (
    <Box
      style={{backgroundColor: countdownBackgroundColor?.color || '#fff'}}
      width="full"
      borderRadius="base"
      padding="4px"
    >
      <Text variant="text7" textAlign="center" style={textColor}>
        {headline}
      </Text>
      <Flex justify="space-evenly">
        <Box>
          <Text variant="heading1" textAlign="center" style={textColor}>
            {hours}
          </Text>
          <Text variant="text4" textAlign="center" style={textColor}>
            HRS
          </Text>
        </Box>
        <Text variant="heading1" textAlign="center" style={textColor}>
          :
        </Text>
        <Box textAlign="center">
          <Text variant="heading1" textAlign="center" style={textColor}>
            {minutes}
          </Text>
          <Text variant="text4" textAlign="center" style={textColor}>
            MINS
          </Text>
        </Box>
        <Text variant="heading1" textAlign="center" style={textColor}>
          :
        </Text>
        <Box>
          <Text variant="heading1" textAlign="center" style={textColor}>
            {seconds}
          </Text>
          <Text variant="text4" textAlign="center" style={textColor}>
            SECS
          </Text>
        </Box>
      </Flex>
    </Box>
  )
}

const standardRestrictedPages = [
  '/basket',
  '/checkout/summary',
  'Iceland-BeforeYouGoPages',
  '/register',
  '/book-delivery',
  '/topup',
  '/orderconfirmation',
  'account/bonus-card/topup'
];

// Extend it for others "sticky" banners.
const StickyBannerContainer: SlotComponent<StickyBannerProps> = ({content}) => {
  const {
    cta,
    image,
    excludedUrlPartials,
    hideOnMobile,
    closeButton,
    timer,
    _meta,
    prizesWonData,
    prizeWonText,
  } = content

  const isVisualisation = useLocation().pathname.includes('visualization')
  const [show, setShow] = useState(false)
  const [animation, setAnimation] = useState<keyof typeof animationMap>('show')

  const {images} = image?.image || {}

  const {isExact: onExcludedPage} = useRouteMatch(excludedUrlPartials || []) || {isExact: false}
  const hasDynamicData = timer || prizesWonData

  useEffect(() => {
    if (animation === 'hide') {
      window.localStorage.setItem(_meta?.deliveryId, 'hide')
    }
  }, [animation, _meta?.deliveryId])

  useEffect(() => {
    if (!window.localStorage.getItem(_meta?.deliveryId)) {
      setShow(true)
    }
  }, [_meta?.deliveryId])

  const isStandardRestrictedPage = standardRestrictedPages.some(page => {
    return useLocation().pathname.includes(page);
  });

  const isExcludedPage = onExcludedPage || isStandardRestrictedPage;

  if (isExcludedPage) {
    return null
  }

  const promotion = {
    creative_name:  image?.image?.images?.[0]?.data?.altText ?? 'Sticky banner missing alt text',
    creative_slot: 'Sticky banner',
    promotion_id: cta?.ctaLink ?? 'Sticky banner without link',
    promotion_name: cta?.ctaText ?? 'Sticky banner without cta',
    link_position: 1
  }

  const sendSelectPromotion = useSelectPromotionTracking()

  return show || isVisualisation ? (
    <Box
      position="fixed"
      zIndex="header"
      onAnimationEnd={() => {
        if (animation === 'hide') {
          setShow(false)
        }
      }}
      style={{
        left: '50%',
        transform: 'translateX(-50%)',
        animationName: animationMap[animation],
      }}
      display={[hideOnMobile ? 'none' : 'block', 'block']}
      className={stickyBannerContainer}
    >
      <Box position="relative" display="block">
        <CmsImage
          images={images}
          width="full"
          imageLink={image?.imageLink}
          queryParams={[
            {w: 1536, fmt: 'auto', qlt: 'default'},
            {w: 3072, fmt: 'auto', qlt: 'default'},
          ]}
        />
        {cta && (
          <Stack
            className={hasDynamicData ? stickyBannerTimerCtaContainer : stickyBannerCtaContainer}
            paddingX={[hasDynamicData ? '40px' : '12px', '40px']}
            paddingY={hasDynamicData ? ['20px', '48px'] : '48px'}
            position="absolute"
            right="0px"
            top={hasDynamicData ? ['unset', '0px'] : '0px'}
            height={hasDynamicData ? ['auto', 'full'] : 'full'}
            justifyContent="center"
            alignItems="center"
            zIndex="overlay"
            spacing="0px"
          >
            {prizesWonData && (
              <PrizesWon prizesWonData={prizesWonData} prizeWonText={prizeWonText} />
            )}
            {timer && <Timer timer={timer} />}
            <CTAButton
              cta={cta}
              width="full"
              minHeight="36px"
              style={hasDynamicData ? {marginTop: '-2px'} : {}}
              onClickCapture={() => sendSelectPromotion(promotion)}
            />
          </Stack>
        )}

        <Button
          variant="unstyled"
          aria-label="Close Banner"
          width="full"
          height="full"
          borderRadius="none"
          paddingX="none"
          onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
            e.preventDefault()
            setAnimation('hide')
          }}
          position="absolute"
          right="0px"
          zIndex="modal"
          className={stickyBannerCloseBtn}
          style={{
            ...(closeButton?.closeBackgroundColor?.color
              ? {backgroundColor: closeButton?.closeBackgroundColor?.color}
              : {}),
          }}
        >
          <CloseIcon
            boxSize="16px"
            style={{
              ...(closeButton?.closeColor?.color ? {color: closeButton?.closeColor?.color} : {}),
            }}
          />
        </Button>
      </Box>
    </Box>
  ) : null
}

export const StickyBanner = observer(StickyBannerContainer)
