import {observer} from 'mobx-react-lite'
import {getAssetUrl} from '@salesforce/pwa-kit-react-sdk/ssr/universal/utils'
import React, {useEffect, useState} from 'react'
import {Layout} from '../../components/layout'
import {BaseButton, Box, Container, Flex, Stack, Text} from '../../vanilla'
import {MealDealItem} from './meal-deals-item'
import {useMealDeals} from './use-meal-deals'
import {Link} from 'react-router-dom'
import {useMealDealsCompleteness} from './use-meal-deal-completeness'
import {CompletenessMessage} from './completeness-message'
import {
  stickyBannerContainer,
  scaleDownFadeOutKeyFrame,
  scaleUpFadeInKeyFrame,
} from '../../components/cms/sticky-banner/styles.css'

const animationMap = {
  show: scaleUpFadeInKeyFrame,
  hide: scaleDownFadeOutKeyFrame,
} as const

const MealDealsContainer: React.FC = () => {
  const {mealDeals, status} = useMealDeals()
  const {complete, update} = useMealDealsCompleteness(mealDeals?.groups)
  const [animation, setAnimation] = useState<keyof typeof animationMap>('show')

  useEffect(() => {
    if (complete) {
      setAnimation('show')
    } else {
      setAnimation('hide')
    }
  }, [complete])

  if (['initial', 'loading'].includes(status)) {
    return null
  }
  const redirectShoppingUrl ='/offers'

  if ((mealDeals == null && status === 'done') || mealDeals?.c_isMealDealLanding !== true) {
    return (
      <Layout>
        <Container paddingX={['0px', '8px']} paddingY="16px">
          <Flex justifyContent="center">
            <Box bg="white" padding="32px" maxWidth="half">
              <Stack gap="16px">
                <Text variant="heading4">Deal Not Available</Text>
                <Text variant="text4">Sorry, this deal is no longer available.</Text>
                <Text variant="text4">
                  Don't worry, we have loads of other great deals available in your area!
                </Text>
                <BaseButton as="a" href={redirectShoppingUrl}>See Other Deals</BaseButton>
              </Stack>
            </Box>
          </Flex>
        </Container>
      </Layout>
    )
  }

  return (
    <>
      <Layout>
        <Container paddingX={['0px', '8px']} paddingY="16px">
          <Stack gap="48px" id={mealDeals.id}>
            {complete ? <CompletenessMessage /> : null}
            {mealDeals.c_contentAsset ? (
              <Box as="img" width="full" src={getAssetUrl(mealDeals.c_contentAsset)} />
            ) : null}
            {mealDeals.groups?.map?.((group, index) => (
              <MealDealItem
                index={index}
                key={`${mealDeals.id}-${group.label}`}
                label={group.label}
                productsIds={group.ids}
                req={group.req}
                setCompleteness={(complete) => {
                  update(index, complete)
                }}
              />
            ))}
            <Box>
              <Flex gap="16px" justifyContent="center">
                <BaseButton to="/basket" as={Link} variant="outlineDark">
                  <Text>View Basket</Text>
                </BaseButton>
                <BaseButton as={Link} to="/">
                  <Text>Continue Shopping</Text>
                </BaseButton>
              </Flex>
            </Box>
            {mealDeals.calloutMsg ? (
              <Box>
                <Text color="gray500">{mealDeals.calloutMsg}</Text>
              </Box>
            ) : null}
          </Stack>
        </Container>
      </Layout>
      {complete ? (
        <Box
          className={stickyBannerContainer}
          position="fixed"
          zIndex="sticky"
          style={{
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            animationName: animationMap[animation],
          }}
        >
          <CompletenessMessage
            onClose={() => {
              setAnimation('hide')
            }}
            showCloseIcon
          />
        </Box>
      ) : null}
    </>
  )
}

export const MealDeals = observer(MealDealsContainer)
