import React, {useEffect, useState} from 'react'
import {
  PayPalScriptProvider,
  BraintreePayPalButtons,
  usePayPalScriptReducer,
  CreateBillingAgreementActions,
  CreateOrderBraintreeActions,
} from '@paypal/react-paypal-js'
import {observer} from 'mobx-react'
import { useBasketStore, useCustomerStore, useGlobalStore, useOrderStore, } from '../../store/hooks/useStore'
import useNavigation from '../../hooks/use-navigation'
import {Flex} from '../../vanilla'
import LoadingOverlay from '../loading-overlay'
import {BraintreePayPalCheckoutCreatePaymentOptions} from '@paypal/react-paypal-js/dist/types/types/braintree/paypalCheckout'
import {flowResult} from 'mobx'
import {AddressFormFields} from '../../types/forms'

interface PaypalPaymentButtonProps {
  amount: number
  onErrorCallback: () => void
  disabled?: boolean,
  isTopUp: boolean,
  paypalClientToken: string | null
  billingAddress?: AddressFormFields
}

export const PaypalPaymentButton = observer(
  ({amount, onErrorCallback, disabled = false, isTopUp, paypalClientToken, billingAddress}: PaypalPaymentButtonProps) => {
    const {
      currency,
      customSitePreferences,
      isCustomSitePreferenceSet,
      getCustomSitePreferenceById,
      paypalClient
    } = useGlobalStore()
    const { customerInfo, topupCustomerBonusCard } = useCustomerStore()
    const { shippingAddress, submitOrder } = useBasketStore()
    const { editMode, endOrderEdit  } = useOrderStore()
    const navigate = useNavigation()

    const [isLoading, setIsLoading] = useState<boolean>(true)

    const vaultedFlow = !!customSitePreferences['BRAINTREE_PAYPAL_VAULTFLOW']
    const readyToRender = paypalClientToken && !isLoading

    const initializePaypalProvider = async () => {
      setIsLoading(true)

      /** Check if BRAINTREE_PAYPAL_VAULTFLOW was fetched from custom pref endpoint.
       * It will affect props of PayPal buttons **/
      if (!isCustomSitePreferenceSet('BRAINTREE_PAYPAL_VAULTFLOW'))
        await getCustomSitePreferenceById('BRAINTREE_PAYPAL_VAULTFLOW')
      setIsLoading(false)
    }

    const handleOrderCreation = (
      _: Record<string, unknown>,
      actions: CreateBillingAgreementActions | CreateOrderBraintreeActions
    ) => {
      if (!actions) return new Promise<string>(() => {})

      const createPaymentPayload = {
        flow: 'checkout',
        amount: amount,
        currency: currency,
        locale: 'en_GB',
        intent: 'authorize',
        enableShippingAddress: true,
        requestBillingAgreement: false,
        shippingAddressEditable: false,
        shippingAddressOverride: {
          recipientName: shippingAddress?.fullName,
          line1: shippingAddress?.address1 || '',
          line2: shippingAddress?.address2 || '',
          city: shippingAddress?.city || '',
          countryCode: shippingAddress?.countryCode?.toUpperCase() || 'GB',
          postalCode: shippingAddress?.postalCode || '',
          state: shippingAddress?.stateCode || '',
          phone: shippingAddress?.phone || '',
        },
      } as BraintreePayPalCheckoutCreatePaymentOptions

      return actions?.braintree?.createPayment({
        ...createPaymentPayload,
        ...(vaultedFlow
          ? {
              requestBillingAgreement: false,
              billingAgreementDescription: customSitePreferences[
                'BRAINTREE_PAYPAL_Billing_Agreement_Description'
              ] as string,
            }
          : {}),
      })
    }

    const handleOrderSubmission = async (nonce: string, cardType: string, billingAddress: AddressFormFields, payPalAccountEmail: string) => {
      const submissionData = {
        btData: {
          nonce, 
          details: { cardType, payPalAccountEmail }
        },
        billingAddress
      }
      
      const order = isTopUp ?
        await flowResult(topupCustomerBonusCard(
          // @ts-ignore
          submissionData,
          amount,
          'PayPal', 
          false
        )) : 
       await flowResult(submitOrder(
        // @ts-ignore
        submissionData,
        'PayPal',
        false
      ))

      if (order?.order_created) {
        const editFinished = editMode
        if (editMode) await endOrderEdit()
        navigate('/orderconfirmation', 'push', {
          order: order,
          isOrderEdited: editFinished,
        })
      } else if (isTopUp && order?.success) {
        navigate('/account/bonus-card/topup/confirmation', 'push', {
          order: order
        })
      } else {
        onErrorCallback()
      }
    }

    useEffect(() => {
      if (customerInfo?.customerNo) {
        initializePaypalProvider()
      }
    }, [customerInfo?.customerNo])

    if (!readyToRender) {
      return <PaypalButtonPlaceholder />
    }

    return (
      <PayPalScriptProvider
        options={{
          components: 'buttons',
          'client-id':
            paypalClient,
          'data-client-token': paypalClientToken,
          intent: 'authorize',
          vault: false,
          currency: currency,
          locale: 'en_GB',
          'enable-funding': 'card,credit,paylater'
        }}
      >
        <BraintreePaypalButtonsWrapper
          vaulted={false}
          amount={amount}
          handlePayPalOrderCreation={handleOrderCreation}
          handleOrderSubmission={handleOrderSubmission}
          onErrorCallback={onErrorCallback}
          disabled={disabled}
          billingAddress={billingAddress}
        />
      </PayPalScriptProvider>
    )
  }
)

interface BraintreePaypalButtonsWrapperProps {
  vaulted: boolean
  amount: number
  handlePayPalOrderCreation: (
    _: Record<string, unknown>,
    actions: CreateBillingAgreementActions | CreateOrderBraintreeActions
  ) => Promise<string>
  handleOrderSubmission: (nonce: string, cartType: string, billingAddress: AddressFormFields, payPalAccountEmail: string) => void
  onErrorCallback?: () => void
  disabled: boolean
  billingAddress?: AddressFormFields
}

const BraintreePaypalButtonsWrapper = ({
  disabled,
  vaulted,
  amount,
  onErrorCallback,
  handlePayPalOrderCreation,
  handleOrderSubmission,
  billingAddress
}: BraintreePaypalButtonsWrapperProps) => {
  const [{isPending}] = usePayPalScriptReducer()

  const dynamicButtonProps ={
    createOrder: handlePayPalOrderCreation,
  }

  if (isPending) {
    return <PaypalButtonPlaceholder />
  }

  return (
    <BraintreePayPalButtons
      style={{label: 'pay', height: 44, layout: 'horizontal', tagline: false, color: 'blue'}}
      fundingSource={'paypal'}
      disabled={disabled}
      forceReRender={[amount]}
      onError={() => onErrorCallback?.()}
      onApprove={async (data, actions) => {
        try {
          const tokenizedPayment = await actions.braintree?.tokenizePayment(data)
          
          if (tokenizedPayment.nonce && tokenizedPayment.details.email && billingAddress) {
            await handleOrderSubmission(tokenizedPayment.nonce, tokenizedPayment.type, billingAddress, tokenizedPayment.details.email)
          }
        } catch (error) {
          console.error(error)
          onErrorCallback?.()
        }
      }}
      {...dynamicButtonProps}
    />
  )
}

const PaypalButtonPlaceholder = () => {
  return (
    <Flex
      style={{width: 150, height: 44, backgroundColor: '#0070ba'}}
      position="relative"
      align="center"
      borderRadius="base"
    >
      <LoadingOverlay
        isLoading
        spinnerSize="sm"
        containerStyles={{backgroundColor: 'rgba(255, 255, 255, 0.8)'}}
      />
    </Flex>
  )
}
